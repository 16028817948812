<template>
	<v-app>
		<v-app-bar app>
			<v-btn icon @click="$router.push(`/store/${storeSlug}`)">
				<v-icon> mdi-chevron-left </v-icon>
			</v-btn>
			<v-toolbar-title>
				<h4>{{ restaurantName }}</h4>
			</v-toolbar-title>
		</v-app-bar>

		<v-main>
			<v-list v-if="cart.length != 0" two-line flat class="py-0">
				<div v-for="(product, index) in cart" :key="product.id">
					<cart-item :id="'swiper_product_' + index" :product="product" />
					<v-divider v-if="index != cart.length - 1" />
				</div>
			</v-list>

			<v-container v-else style="margin-top: 20vh">
				<v-row justify="center" align="center">
					<v-icon color="primary"> mdi-information-outline </v-icon>
					<h4 class="ml-2">Cart is empty!</h4>
				</v-row>
			</v-container>
		</v-main>

		<v-bottom-navigation app grow fixed>
			<v-btn color="primary darken-2">
				<span class="white--text">{{ personCount }} Person</span>
				<h2 class="white--text">₺{{ (cartTotal / personCount).toFixed(2) }}</h2>
			</v-btn>

			<v-btn @click="increasePersonCount">
				<span>Add Person</span>

				<v-icon>mdi-plus</v-icon>
			</v-btn>

			<v-btn @click="decreasePersonCount">
				<span>Remove Person</span>

				<v-icon>mdi-minus</v-icon>
			</v-btn>

			<v-btn
				color="primary darken-2"
				@click="
					qrCodeSheet = !qrCodeSheet;
					generateQrCode();
				"
			>
				<span class="white--text">Show QrCode</span>
				<v-icon class="white--text">mdi-qrcode</v-icon>
			</v-btn>
		</v-bottom-navigation>

		<v-bottom-sheet v-model="qrCodeSheet">
			<v-sheet class="text-center" height="60vh" style="border-radius: 15px 15px 0 0">
				<v-col>
					<v-row justify="center">
						<h3 class="pt-5">Show this qr code to your waiter</h3>
					</v-row>
					<v-row justify="center">
						<vue-qrcode :width="width" :value="qrCode" contain />
					</v-row>
					<v-row justify="center">
						<v-btn color="primary darken-2" @click="shareCart()">
							<span class="white--text">Share Cart</span>
							<v-icon class="white--text">mdi-share</v-icon>
						</v-btn>
					</v-row>
				</v-col>
			</v-sheet>
		</v-bottom-sheet>

		<v-dialog v-model="copyDialog" width="400">
			<v-card>
				<v-card-title class="text-h5 lighten-2"> </v-card-title>

				<v-card-text class="text-h5 black--text">
					Cart Link copied
				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" text @click="copyDialog = false"> OK </v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-app>
</template>

<script>
import CartItem from "../components/CartItem.vue";
import VueQrcode from "vue-qrcode";
import { FirebaseDynamicLinks } from "firebase-dynamic-links";
import axios from "axios";
import tempData from "../assets/tempData";

const firebaseDynamicLinks = new FirebaseDynamicLinks("AIzaSyBaW8ZZJukhHU8mVUJPpwIDu39IuIdJTI8");

export default {
	name: "Cart",
	components: { VueQrcode, CartItem },
	data: function() {
		return {
			storeSlug: "",
			qrCodeSheet: false,
			copyDialog: false,
			isLoading: true,
			isError: false,
			error: null,
			store: {},
			deskId: 12,
			personCount: 1,
			width: 0,
			qrCode: "",
		};
	},
	methods: {
		async getStore() {
			this.storeSlug = this.$route.params.storeSlug;

			axios
				.get(`https://cors-anywhere.herokuapp.com/https://food-client-api-gateway.getirapi.com/restaurants/${this.storeSlug}`)
				.then((res) => {
					this.store = res.data.data;
					document.title = this.store.restaurant.name;

					this.isLoading = false;
				})
				.catch((err) => {
					console.error(err);
					this.isLoading = false;
					this.isError = true;
					this.error = err;

					// TODO: Testing only
					this.isError = false;
					this.store = tempData.data;
					document.title = this.store.restaurant.name;
				});
		},
		increasePersonCount() {
			this.personCount++;
		},
		decreasePersonCount() {
			if (this.personCount > 1) {
				this.personCount--;
			}
		},

		async generateQrCode() {
			const { shortLink, previewLink } = await firebaseDynamicLinks.createLink({
				dynamicLinkInfo: {
					domainUriPrefix: "https://doyurbeni.page.link",
					link:
						"https://app.nearbs.com/waiter?data=" +
						JSON.stringify({
							id: this.store.restaurant.id,
							desk: this.deskId,
							count: this.personCount,
							cart: this.cart.map((product) => {
								return {
									quantity: product.quantity,
									id: product.id,
								};
							}),
						}),
					androidInfo: {
						androidPackageName: "com.bartuozel.doyurbeni",
					},
					iosInfo: {
						iosBundleId: "com.bartuozel.doyurbeni",
					},
				},
			});
			console.log(shortLink, previewLink);

			this.qrCode =
				"https://app.nearbs.com/waiter?data=" +
				JSON.stringify({
					id: this.store.restaurant.id,
					desk: this.deskId,
					count: this.personCount,
					cart: this.cart.map((product) => {
						return {
							quantity: product.quantity,
							id: product.id,
						};
					}),
				});
		},
		onResize() {
			this.width = Math.min(
				(window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) * 0.6 - 120,
				window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
			);
		},
		shareCart() {
			if (navigator.share) {
				navigator.share({
					title: "Doyur Beni Cart",
					url: this.qrCode,
				});
			} else {
				this.$copyText(this.qrCode).then(
					(e) => {
						this.copyDialog = true;
						console.log(e);
					},
					(e) => {
						alert("Can not copy");
						console.log(e);
					}
				);
			}
		},
		onRefresh() {
			location.reload();
		},
		onMailClick() {
			window.open(`mailto:bug@doyurbeni.com?subject=Bug Report&body=Hello,\n`);
		},
	},
	created: async function() {
		await this.getStore();

		if (this.cart.length == 0) {
			this.$router.push(`/store/${this.storeSlug}`);
		}
	},
	mounted() {
		window.addEventListener("resize", this.onResize);
		this.width = Math.min(
			(window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) * 0.6 - 120,
			window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
		);
	},

	beforeDestroy() {
		window.removeEventListener("resize", this.onResize);
	},
	computed: {
		restaurantName() {
			if (this.store && this.store.restaurant && this.store.restaurant.name) {
				return this.store.restaurant.name;
			} else {
				return "";
			}
		},
		cart() {
			return this.$store.state.cart;
		},
		cartCount() {
			return this.$store.getters.cartLength;
		},
		cartTotal() {
			return this.$store.getters.cartTotal;
		},
	},

	updated: function() {
		this.$nextTick(function() {
			if (this.$store.getters.cartLength == 0) {
				this.$router.push(`/store/${this.storeSlug}`);
			}
		});
	},
};
</script>
