const menu = [
	{
		name: "Atölye Pub",
		id: "atolye-pub",
		categories: [
			{
				id: 219795,
				name: "Kudret Hanım'dan Tostlar",
				sortNo: 1,
				photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/category.png",
				categoryPhoto: null,
				products: [
					{
						id: 2179497,
						name: "Kavurmalı Kaşarlı Tost",
						description: "Kavurma, kaşar peyniri",
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219795,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: false,
						isUseProductMenu: false,
						isUseProductFeature: false,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2311901,
							isDefault: true,
							price: 35.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2311901,
								isDefault: true,
								price: 35.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
					{
						id: 2179524,
						name: "Sucuklu Kaşarlı Tost",
						description: "Sucuk, Kaşar peyniri",
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219795,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2311933,
							isDefault: true,
							price: 25.0,
							unitId: 30615,
							unitName: "Hindi",
						},
						productUnits: [
							{
								id: 2311933,
								isDefault: true,
								price: 25.0,
								unitId: 30615,
								unitName: "Hindi",
							},
							{
								id: 2311934,
								isDefault: false,
								price: 30.0,
								unitId: 30616,
								unitName: "Et",
							},
						],
					},
					{
						id: 2179525,
						name: "Sucuklu Tost",
						description: "Sucuk",
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219795,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2311935,
							isDefault: true,
							price: 25.0,
							unitId: 30615,
							unitName: "Hindi",
						},
						productUnits: [
							{
								id: 2311935,
								isDefault: true,
								price: 25.0,
								unitId: 30615,
								unitName: "Hindi",
							},
							{
								id: 2311936,
								isDefault: false,
								price: 29.0,
								unitId: 30616,
								unitName: "Et",
							},
						],
					},
					{
						id: 2179527,
						name: "Kaşarlı, Cheddar Peynirli Tost",
						description: "Kaşar Peyniri, Cheddar Peyniri",
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219795,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2311938,
							isDefault: true,
							price: 28.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2311938,
								isDefault: true,
								price: 28.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
					{
						id: 2179528,
						name: "Ispanaklı, Kaşarlı Tost",
						description: "Ispanak, Kaşar peyniri",
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219795,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2311939,
							isDefault: true,
							price: 26.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2311939,
								isDefault: true,
								price: 26.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
					{
						id: 2179533,
						name: "Tavuk Şinitzel, Kaşarlı Tost",
						description: "Tavuk Şinitzel, Kaşar peyniri",
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219795,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2311947,
							isDefault: true,
							price: 30.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2311947,
								isDefault: true,
								price: 30.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
					{
						id: 2179537,
						name: "Avokadolu, Kaşarlı Tost",
						description: "Avokado, Kaşar peyniri, Limon, Kara biber",
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219795,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2311951,
							isDefault: true,
							price: 30.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2311951,
								isDefault: true,
								price: 30.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
					{
						id: 2179586,
						name: "Mantarlı, Kaşarlı Tost",
						description: "Mantar, Kaşar peyniri\n",
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219795,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312008,
							isDefault: true,
							price: 28.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2312008,
								isDefault: true,
								price: 28.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
					{
						id: 2179588,
						name: "Mantarlı, Kaşarlı, Kavurmalı Tost",
						description: "Mantar, Kaşar peyniri, Kavurma",
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219795,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312010,
							isDefault: true,
							price: 45.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2312010,
								isDefault: true,
								price: 45.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
					{
						id: 2179526,
						name: "Kaşarlı Tost",
						description: "Kaşar",
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219795,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 3,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2311937,
							isDefault: true,
							price: 25.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2311937,
								isDefault: true,
								price: 25.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
				],
			},
			{
				id: 219796,
				name: "Ayvalık Tostları",
				sortNo: 2,
				photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/category.png",
				categoryPhoto: null,
				products: [
					{
						id: 2179589,
						name: "Kaşarlı Ayvalık Tost",
						description: "Üç katlı ayvalık ekmeğinde; kaşar peyniri",
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219796,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312011,
							isDefault: true,
							price: 28.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2312011,
								isDefault: true,
								price: 28.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
					{
						id: 2179592,
						name: "Kavurmalı Kaşarlı Ayvalık Tostu",
						description: "Üç katlı ayvalık ekmeğinde; kaşar peyniri, kavurma, rus salatası, turşu, domates, salça sos, mayonez",
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219796,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312014,
							isDefault: true,
							price: 48.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2312014,
								isDefault: true,
								price: 48.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
					{
						id: 2179594,
						name: "Süper Karışık Ayvalık Tostu",
						description: "Üç katlı ayvalık ekmeğinde; kaşar peyniri, sucuk, salam, sosis, rus salatası, turşu, domates, salça sos, mayonez",
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219796,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312016,
							isDefault: true,
							price: 38.0,
							unitId: 30615,
							unitName: "Hindi",
						},
						productUnits: [
							{
								id: 2312016,
								isDefault: true,
								price: 38.0,
								unitId: 30615,
								unitName: "Hindi",
							},
							{
								id: 2312017,
								isDefault: false,
								price: 45.0,
								unitId: 30616,
								unitName: "Et",
							},
						],
					},
					{
						id: 2179595,
						name: "Karışık Ayvalık",
						description: "Üç katlı ayvalık ekmeğinde; kaşar peyniri, sucuk, salam, rus salatası, turşu, domates, salça sos, mayonez",
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219796,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312018,
							isDefault: true,
							price: 35.0,
							unitId: 30615,
							unitName: "Hindi",
						},
						productUnits: [
							{
								id: 2312018,
								isDefault: true,
								price: 35.0,
								unitId: 30615,
								unitName: "Hindi",
							},
							{
								id: 2312019,
								isDefault: false,
								price: 42.0,
								unitId: 30616,
								unitName: "Et",
							},
						],
					},
				],
			},
			{
				id: 219797,
				name: "Atıştırmalıklar",
				sortNo: 3,
				photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/category.png",
				categoryPhoto: null,
				products: [
					{
						id: 2179596,
						name: "Sosisli Patates Kızartması",
						description: "sosis parçaları, patates kızartması",
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219797,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312020,
							isDefault: true,
							price: 28.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2312020,
								isDefault: true,
								price: 28.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
					{
						id: 2179599,
						name: "Peynirli Sigara Böreği",
						description: "12 adet sigara böreği, domates, salatalık, turşu, zeytin",
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219797,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312024,
							isDefault: true,
							price: 30.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2312024,
								isDefault: true,
								price: 30.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
					{
						id: 2179600,
						name: "Paçanga Böreği (3 Adet)",
						description: "pastırma, kaşar peyniri",
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219797,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312025,
							isDefault: true,
							price: 40.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2312025,
								isDefault: true,
								price: 40.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
					{
						id: 2179602,
						name: "Patates Kızartması",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219797,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312027,
							isDefault: true,
							price: 25.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2312027,
								isDefault: true,
								price: 25.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
					{
						id: 2179604,
						name: "Sıcak Sepeti",
						description: "patates kızartması, tavuk topları, nugget, sosis parçaları",
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219797,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312030,
							isDefault: true,
							price: 30.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2312030,
								isDefault: true,
								price: 30.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
					{
						id: 2179605,
						name: "Mozzarella Sticks (8 Adet",
						description: "mozzerella çubukları",
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219797,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312031,
							isDefault: true,
							price: 43.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2312031,
								isDefault: true,
								price: 43.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
					{
						id: 2245500,
						name: " 12 'li Nugget Sepeti (2'li sos)",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219797,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 6,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2383829,
							isDefault: true,
							price: 30.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2383829,
								isDefault: true,
								price: 30.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
				],
			},
			{
				id: 219798,
				name: "İlkay Paşa'dan Sandviçler",
				sortNo: 4,
				photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/category.png",
				categoryPhoto: null,
				products: [
					{
						id: 2179610,
						name: "Cheddar Sosisli Sandviç",
						description: "cheddar peyniri, sosis,rus salatası, turşu, salça sos",
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219798,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312036,
							isDefault: true,
							price: 30.0,
							unitId: 30615,
							unitName: "Hindi",
						},
						productUnits: [
							{
								id: 2312036,
								isDefault: true,
								price: 30.0,
								unitId: 30615,
								unitName: "Hindi",
							},
							{
								id: 2312037,
								isDefault: false,
								price: 35.0,
								unitId: 30616,
								unitName: "Et",
							},
						],
					},
					{
						id: 2179613,
						name: "Üç Peynirli Sandviç",
						description: "kaşar peyniri, cheddar peyniri, ezine peyniri, domates, biber",
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219798,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312040,
							isDefault: true,
							price: 28.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2312040,
								isDefault: true,
								price: 28.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
					{
						id: 2179616,
						name: "Karışık Sandviç",
						description: "sucuk, salam, kaşar peyniri, rus salatası, salça sos, turşu",
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219798,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312043,
							isDefault: true,
							price: 28.0,
							unitId: 30615,
							unitName: "Hindi",
						},
						productUnits: [
							{
								id: 2312043,
								isDefault: true,
								price: 28.0,
								unitId: 30615,
								unitName: "Hindi",
							},
							{
								id: 2312044,
								isDefault: false,
								price: 38.0,
								unitId: 30616,
								unitName: "Et",
							},
						],
					},
					{
						id: 2179617,
						name: "Dana Jambonlu Sandviç",
						description: "dana jambon eti, rus salatası, salça sos, turşu",
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219798,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312045,
							isDefault: true,
							price: 28.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2312045,
								isDefault: true,
								price: 28.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
					{
						id: 2179618,
						name: "Hindi Jambonlu Sandviç",
						description: "hindi jambon eti, rus salatası, salça sos, turşu",
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219798,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312046,
							isDefault: true,
							price: 28.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2312046,
								isDefault: true,
								price: 28.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
					{
						id: 2179620,
						name: "Sosisli Sandviç",
						description: "sosis, rus salatası, salça sos, turşu",
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219798,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312048,
							isDefault: true,
							price: 25.0,
							unitId: 30615,
							unitName: "Hindi",
						},
						productUnits: [
							{
								id: 2312048,
								isDefault: true,
								price: 25.0,
								unitId: 30615,
								unitName: "Hindi",
							},
							{
								id: 2312049,
								isDefault: false,
								price: 35.0,
								unitId: 30616,
								unitName: "Et",
							},
						],
					},
					{
						id: 2179621,
						name: "Kavurmalı Sandviç",
						description: "kavurma, rus salatası, salça sos, turşu",
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219798,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312050,
							isDefault: true,
							price: 40.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2312050,
								isDefault: true,
								price: 40.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
					{
						id: 2179622,
						name: "Beyaz Peynirli Sandviç",
						description: "beyaz peynir, domates, biber",
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219798,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312051,
							isDefault: true,
							price: 25.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2312051,
								isDefault: true,
								price: 25.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
					{
						id: 2179623,
						name: "Kavurmalı Kaşarlı Sandviç",
						description: "kavurma, kaşar peyniri, rus salatası, salça sos, turşu",
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219798,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312052,
							isDefault: true,
							price: 45.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2312052,
								isDefault: true,
								price: 45.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
					{
						id: 2179625,
						name: "Tavuk Şinitzel Sandviç ",
						description: "tavuk şinitzel, rus salatası, salça sos, turşu",
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219798,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312054,
							isDefault: true,
							price: 30.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2312054,
								isDefault: true,
								price: 30.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
					{
						id: 2179629,
						name: "Salamlı Sandviç",
						description: "salam, rus salatası, salça sos, turşu",
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219798,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312058,
							isDefault: true,
							price: 25.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2312058,
								isDefault: true,
								price: 25.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
					{
						id: 2179630,
						name: "Kaşarlı Sandviç",
						description: "kaşar peyniri, rus salatası, salça sos, turşu",
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219798,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312059,
							isDefault: true,
							price: 25.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2312059,
								isDefault: true,
								price: 25.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
					{
						id: 2193209,
						name: "Ton Balıklı Sandviç",
						description: "Ton balığı, rus salatası, salça sos, turşu",
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219798,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 13,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2327096,
							isDefault: true,
							price: 37.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2327096,
								isDefault: true,
								price: 37.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
					{
						id: 2197144,
						name: "Zeytin Ezmeli Sandviç ",
						description: "Zeytin ezmesi, kaşar peyniri, domates",
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219798,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 14,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2331444,
							isDefault: true,
							price: 25.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2331444,
								isDefault: true,
								price: 25.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
					{
						id: 2197146,
						name: "Sucuklu Sandviç ",
						description: "Sucuk, rus salatası, salça sos, turşu ",
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219798,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 15,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2331447,
							isDefault: true,
							price: 25.0,
							unitId: 30615,
							unitName: "Hindi",
						},
						productUnits: [
							{
								id: 2331447,
								isDefault: true,
								price: 25.0,
								unitId: 30615,
								unitName: "Hindi",
							},
							{
								id: 2331448,
								isDefault: false,
								price: 35.0,
								unitId: 30616,
								unitName: "Et",
							},
						],
					},
				],
			},
			{
				id: 219799,
				name: "Bazlama Tostlar",
				sortNo: 5,
				photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/category.png",
				categoryPhoto: null,
				products: [
					{
						id: 2179631,
						name: "Kavurmalı, Kaşarlı Bazlama Tostu ",
						description: "Kavurma, kaşar peyniri",
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219799,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312060,
							isDefault: true,
							price: 48.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2312060,
								isDefault: true,
								price: 48.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
					{
						id: 2179632,
						name: "Kavurmalı Bazlama Tostu",
						description: "Kavurma",
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219799,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312061,
							isDefault: true,
							price: 45.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2312061,
								isDefault: true,
								price: 45.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
					{
						id: 2179633,
						name: "Special Bazlama Tostu ",
						description: "Sucuk, kaşar peyniri, kavurma",
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219799,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312062,
							isDefault: true,
							price: 50.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2312062,
								isDefault: true,
								price: 50.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
					{
						id: 2179634,
						name: "Kaşarlı Bazlama Tostu",
						description: "Kaşar peyniri",
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219799,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312064,
							isDefault: true,
							price: 30.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2312064,
								isDefault: true,
								price: 30.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
					{
						id: 2179635,
						name: "Sucuklu, Kaşarlı Bazlama Tostu",
						description: "Sucuk, kaşar peyniri",
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219799,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312065,
							isDefault: true,
							price: 35.0,
							unitId: 30615,
							unitName: "Hindi",
						},
						productUnits: [
							{
								id: 2312066,
								isDefault: false,
								price: 43.0,
								unitId: 30616,
								unitName: "Et",
							},
							{
								id: 2312065,
								isDefault: true,
								price: 35.0,
								unitId: 30615,
								unitName: "Hindi",
							},
						],
					},
					{
						id: 2179636,
						name: "Nutella Bazlama Tostu",
						description: "Nutella, pudra şekeri",
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219799,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312067,
							isDefault: true,
							price: 30.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2312067,
								isDefault: true,
								price: 30.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
					{
						id: 2233499,
						name: "Muzlu Nutella Bazlama Tostu",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219799,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 6,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2370642,
							isDefault: true,
							price: 38.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2370642,
								isDefault: true,
								price: 38.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
					{
						id: 2344991,
						name: "Tavuk Şinitzelli, Kaşarlı Bazlama Tostu",
						description: "Tavuk Şinitzel, Kaşar Peyniri",
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219799,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 7,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2491734,
							isDefault: true,
							price: 40.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2491734,
								isDefault: true,
								price: 40.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
					{
						id: 2345007,
						name: "Tavuk Şinitzelli Bazlama Tostu",
						description: "Tavuk Şinitzel",
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219799,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 8,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2491751,
							isDefault: true,
							price: 35.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2491751,
								isDefault: true,
								price: 35.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
				],
			},
			{
				id: 219800,
				name: "Vitamin Bar",
				sortNo: 6,
				photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/category.png",
				categoryPhoto: null,
				products: [
					{
						id: 2179640,
						name: "Taze Sıkım Elma Suyu",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219800,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312071,
							isDefault: true,
							price: 25.0,
							unitId: 30617,
							unitName: "33cl",
						},
						productUnits: [
							{
								id: 2312071,
								isDefault: true,
								price: 25.0,
								unitId: 30617,
								unitName: "33cl",
							},
						],
					},
					{
						id: 2179641,
						name: "Taze Sıkım Havuç Suyu",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219800,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312072,
							isDefault: true,
							price: 20.0,
							unitId: 30617,
							unitName: "33cl",
						},
						productUnits: [
							{
								id: 2312072,
								isDefault: true,
								price: 20.0,
								unitId: 30617,
								unitName: "33cl",
							},
						],
					},
					{
						id: 2179645,
						name: "Taze Sıkım Portakal Suyu",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219800,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312077,
							isDefault: true,
							price: 20.0,
							unitId: 30617,
							unitName: "33cl",
						},
						productUnits: [
							{
								id: 2312077,
								isDefault: true,
								price: 20.0,
								unitId: 30617,
								unitName: "33cl",
							},
						],
					},
					{
						id: 2179647,
						name: "Taze sıkım Greyfurt Suyu",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219800,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312079,
							isDefault: true,
							price: 20.0,
							unitId: 30617,
							unitName: "33cl",
						},
						productUnits: [
							{
								id: 2312079,
								isDefault: true,
								price: 20.0,
								unitId: 30617,
								unitName: "33cl",
							},
						],
					},
					{
						id: 2179652,
						name: "Taze Sıkım Limon, Portakal Suyu ",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219800,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312084,
							isDefault: true,
							price: 20.0,
							unitId: 30617,
							unitName: "33cl",
						},
						productUnits: [
							{
								id: 2312084,
								isDefault: true,
								price: 20.0,
								unitId: 30617,
								unitName: "33cl",
							},
						],
					},
					{
						id: 2179655,
						name: "Ev Yapımı Limonata",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219800,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312087,
							isDefault: true,
							price: 20.0,
							unitId: 30617,
							unitName: "33cl",
						},
						productUnits: [
							{
								id: 2312087,
								isDefault: true,
								price: 20.0,
								unitId: 30617,
								unitName: "33cl",
							},
						],
					},
					{
						id: 2179656,
						name: "Ev Yapımı Çilekli Limonata",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219800,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312088,
							isDefault: true,
							price: 22.0,
							unitId: 30617,
							unitName: "33cl",
						},
						productUnits: [
							{
								id: 2312088,
								isDefault: true,
								price: 22.0,
								unitId: 30617,
								unitName: "33cl",
							},
						],
					},
					{
						id: 2179657,
						name: "Gribal",
						description: "Portakal, greyfurt, nar, limon",
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219800,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312089,
							isDefault: true,
							price: 30.0,
							unitId: 30618,
							unitName: "43cl",
						},
						productUnits: [
							{
								id: 2312089,
								isDefault: true,
								price: 30.0,
								unitId: 30618,
								unitName: "43cl",
							},
						],
					},
					{
						id: 2179658,
						name: "Atom",
						description: "Yeşil elma, havuç, portakal, nar, ananas, muz, bal",
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219800,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312090,
							isDefault: true,
							price: 30.0,
							unitId: 30618,
							unitName: "43cl",
						},
						productUnits: [
							{
								id: 2312090,
								isDefault: true,
								price: 30.0,
								unitId: 30618,
								unitName: "43cl",
							},
						],
					},
					{
						id: 2179660,
						name: "Special Kokteyl",
						description: "Portakal, yeşil elma, havuç, greyfurt, limon, nar, kivi, ananas",
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219800,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312092,
							isDefault: true,
							price: 30.0,
							unitId: 30618,
							unitName: "43cl",
						},
						productUnits: [
							{
								id: 2312092,
								isDefault: true,
								price: 30.0,
								unitId: 30618,
								unitName: "43cl",
							},
						],
					},
					{
						id: 2179662,
						name: "Gripmix",
						description: "Portakal, yeşil elma, greyfurt, kivi, limon",
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219800,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312094,
							isDefault: true,
							price: 30.0,
							unitId: 30618,
							unitName: "43cl",
						},
						productUnits: [
							{
								id: 2312094,
								isDefault: true,
								price: 30.0,
								unitId: 30618,
								unitName: "43cl",
							},
						],
					},
					{
						id: 2179663,
						name: "Zeus",
						description: "Kırmızı elma, ananas, muz, kivi, avokado",
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219800,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312095,
							isDefault: true,
							price: 30.0,
							unitId: 30618,
							unitName: "43cl",
						},
						productUnits: [
							{
								id: 2312095,
								isDefault: true,
								price: 30.0,
								unitId: 30618,
								unitName: "43cl",
							},
						],
					},
					{
						id: 2179666,
						name: "Muzlu Süt",
						description: "Muz, bal, süt",
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219800,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2382179,
							isDefault: true,
							price: 25.0,
							unitId: 30617,
							unitName: "33cl",
						},
						productUnits: [
							{
								id: 2382179,
								isDefault: true,
								price: 25.0,
								unitId: 30617,
								unitName: "33cl",
							},
						],
					},
					{
						id: 2194892,
						name: "Ev Yapımı Böğürtlenli Limonata ",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219800,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 17,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2328947,
							isDefault: true,
							price: 22.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2328947,
								isDefault: true,
								price: 22.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
				],
			},
			{
				id: 219801,
				name: "Alkollü İçecekler",
				sortNo: 7,
				photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/category.png",
				categoryPhoto: null,
				products: [
					{
						id: 2179669,
						name: "Tuborg Gold",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/2179669_38142.jpg?202205230222",
						categoryId: 219801,
						taxRate: 18.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312101,
							isDefault: true,
							price: 28.0,
							unitId: 30619,
							unitName: "50cl",
						},
						productUnits: [
							{
								id: 2312101,
								isDefault: true,
								price: 28.0,
								unitId: 30619,
								unitName: "50cl",
							},
						],
					},
					{
						id: 2179670,
						name: "Tuborg Filtresiz",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/2179670_38142.jpg?202205230230",
						categoryId: 219801,
						taxRate: 18.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312102,
							isDefault: true,
							price: 33.0,
							unitId: 30619,
							unitName: "50cl",
						},
						productUnits: [
							{
								id: 2312102,
								isDefault: true,
								price: 33.0,
								unitId: 30619,
								unitName: "50cl",
							},
						],
					},
					{
						id: 2179674,
						name: "Tuborg Amber",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/2179674_38142.jpg?202205230231",
						categoryId: 219801,
						taxRate: 18.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312106,
							isDefault: true,
							price: 33.0,
							unitId: 30619,
							unitName: "50cl",
						},
						productUnits: [
							{
								id: 2312106,
								isDefault: true,
								price: 33.0,
								unitId: 30619,
								unitName: "50cl",
							},
						],
					},
					{
						id: 2179676,
						name: "Carlsberg",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/2179676_38142.jpg?202205230227",
						categoryId: 219801,
						taxRate: 18.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312108,
							isDefault: true,
							price: 35.0,
							unitId: 30619,
							unitName: "50cl",
						},
						productUnits: [
							{
								id: 2312108,
								isDefault: true,
								price: 35.0,
								unitId: 30619,
								unitName: "50cl",
							},
						],
					},
					{
						id: 2179678,
						name: "Frederik",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/2179678_38142.jpg?202205230227",
						categoryId: 219801,
						taxRate: 18.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312110,
							isDefault: true,
							price: 43.0,
							unitId: 30617,
							unitName: "33cl",
						},
						productUnits: [
							{
								id: 2312110,
								isDefault: true,
								price: 43.0,
								unitId: 30617,
								unitName: "33cl",
							},
						],
					},
					{
						id: 2179679,
						name: "Sol",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/2179679_38142.jpg?202205230227",
						categoryId: 219801,
						taxRate: 18.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312111,
							isDefault: true,
							price: 43.0,
							unitId: 30617,
							unitName: "33cl",
						},
						productUnits: [
							{
								id: 2312111,
								isDefault: true,
								price: 43.0,
								unitId: 30617,
								unitName: "33cl",
							},
						],
					},
					{
						id: 2179680,
						name: "Desperados'",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/2179680_38142.jpg?202205230228",
						categoryId: 219801,
						taxRate: 18.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312112,
							isDefault: true,
							price: 45.0,
							unitId: 30617,
							unitName: "33cl",
						},
						productUnits: [
							{
								id: 2312112,
								isDefault: true,
								price: 45.0,
								unitId: 30617,
								unitName: "33cl",
							},
						],
					},
					{
						id: 2179681,
						name: "Weihenstephen",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/2179681_38142.jpg?202205230228",
						categoryId: 219801,
						taxRate: 18.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312113,
							isDefault: true,
							price: 45.0,
							unitId: 30617,
							unitName: "33cl",
						},
						productUnits: [
							{
								id: 2312113,
								isDefault: true,
								price: 45.0,
								unitId: 30617,
								unitName: "33cl",
							},
						],
					},
					{
						id: 2179684,
						name: "Cin Double",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/2179684_38142.jpg?202205230234",
						categoryId: 219801,
						taxRate: 18.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312116,
							isDefault: true,
							price: 90.0,
							unitId: 30621,
							unitName: "Duble",
						},
						productUnits: [
							{
								id: 2312116,
								isDefault: true,
								price: 90.0,
								unitId: 30621,
								unitName: "Duble",
							},
						],
					},
					{
						id: 2179687,
						name: "Cin Tonik",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/2179687_38142.jpg?202205230234",
						categoryId: 219801,
						taxRate: 18.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312119,
							isDefault: true,
							price: 60.0,
							unitId: 30620,
							unitName: "Tek",
						},
						productUnits: [
							{
								id: 2312119,
								isDefault: true,
								price: 60.0,
								unitId: 30620,
								unitName: "Tek",
							},
						],
					},
					{
						id: 2179689,
						name: "Vodka Tek",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/2179689_38142.jpg?202205230235",
						categoryId: 219801,
						taxRate: 18.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312121,
							isDefault: true,
							price: 50.0,
							unitId: 30620,
							unitName: "Tek",
						},
						productUnits: [
							{
								id: 2312121,
								isDefault: true,
								price: 50.0,
								unitId: 30620,
								unitName: "Tek",
							},
						],
					},
					{
						id: 2179806,
						name: "Vodka Double",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/2179806_38142.jpg?202205230235",
						categoryId: 219801,
						taxRate: 18.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312238,
							isDefault: true,
							price: 90.0,
							unitId: 30621,
							unitName: "Duble",
						},
						productUnits: [
							{
								id: 2312238,
								isDefault: true,
								price: 90.0,
								unitId: 30621,
								unitName: "Duble",
							},
						],
					},
					{
						id: 2179808,
						name: "Vodka Enerji",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/2179808_38142.jpg?202205230235",
						categoryId: 219801,
						taxRate: 18.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312240,
							isDefault: true,
							price: 60.0,
							unitId: 30620,
							unitName: "Tek",
						},
						productUnits: [
							{
								id: 2312240,
								isDefault: true,
								price: 60.0,
								unitId: 30620,
								unitName: "Tek",
							},
						],
					},
					{
						id: 2179809,
						name: "Tekila Shot",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/2179809_38142.jpg?202205230236",
						categoryId: 219801,
						taxRate: 18.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312241,
							isDefault: true,
							price: 35.0,
							unitId: 30620,
							unitName: "Tek",
						},
						productUnits: [
							{
								id: 2312241,
								isDefault: true,
								price: 35.0,
								unitId: 30620,
								unitName: "Tek",
							},
						],
					},
					{
						id: 2179811,
						name: "Viski Tek",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/2179811_38142.jpg?202205230236",
						categoryId: 219801,
						taxRate: 18.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312243,
							isDefault: true,
							price: 60.0,
							unitId: 30620,
							unitName: "Tek",
						},
						productUnits: [
							{
								id: 2312243,
								isDefault: true,
								price: 60.0,
								unitId: 30620,
								unitName: "Tek",
							},
						],
					},
					{
						id: 2179813,
						name: "Viski Double",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/2179813_38142.jpg?202205230236",
						categoryId: 219801,
						taxRate: 18.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312245,
							isDefault: true,
							price: 90.0,
							unitId: 30621,
							unitName: "Duble",
						},
						productUnits: [
							{
								id: 2312245,
								isDefault: true,
								price: 90.0,
								unitId: 30621,
								unitName: "Duble",
							},
						],
					},
					{
						id: 2179818,
						name: "Rose Şarap",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/2179818_38142.jpg?202205230238",
						categoryId: 219801,
						taxRate: 18.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312250,
							isDefault: true,
							price: 50.0,
							unitId: 30622,
							unitName: "Kadeh",
						},
						productUnits: [
							{
								id: 2312250,
								isDefault: true,
								price: 50.0,
								unitId: 30622,
								unitName: "Kadeh",
							},
						],
					},
					{
						id: 2179821,
						name: "Beyaz Şarap ",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/2179821_38142.jpg?202205230238",
						categoryId: 219801,
						taxRate: 18.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312253,
							isDefault: true,
							price: 50.0,
							unitId: 30622,
							unitName: "Kadeh",
						},
						productUnits: [
							{
								id: 2312253,
								isDefault: true,
								price: 50.0,
								unitId: 30622,
								unitName: "Kadeh",
							},
						],
					},
					{
						id: 2179824,
						name: "Rakı Tek",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/2179824_38142.jpg?202205230240",
						categoryId: 219801,
						taxRate: 18.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312256,
							isDefault: true,
							price: 50.0,
							unitId: 30620,
							unitName: "Tek",
						},
						productUnits: [
							{
								id: 2312256,
								isDefault: true,
								price: 50.0,
								unitId: 30620,
								unitName: "Tek",
							},
						],
					},
					{
						id: 2179826,
						name: "Rakı Double",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/2179826_38142.jpg?202205230240",
						categoryId: 219801,
						taxRate: 18.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312258,
							isDefault: true,
							price: 80.0,
							unitId: 30621,
							unitName: "Duble",
						},
						productUnits: [
							{
								id: 2312258,
								isDefault: true,
								price: 80.0,
								unitId: 30621,
								unitName: "Duble",
							},
						],
					},
					{
						id: 2179683,
						name: "Cin Tek",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/2179683_38142.jpg?202205230234",
						categoryId: 219801,
						taxRate: 18.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 8,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312115,
							isDefault: true,
							price: 50.0,
							unitId: 30620,
							unitName: "Tek",
						},
						productUnits: [
							{
								id: 2312115,
								isDefault: true,
								price: 50.0,
								unitId: 30620,
								unitName: "Tek",
							},
						],
					},
					{
						id: 2179817,
						name: "Kırmızı Şarap",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/2179817_38142.jpg?202205230238",
						categoryId: 219801,
						taxRate: 18.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 17,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312249,
							isDefault: true,
							price: 50.0,
							unitId: 30622,
							unitName: "Kadeh",
						},
						productUnits: [
							{
								id: 2312249,
								isDefault: true,
								price: 50.0,
								unitId: 30622,
								unitName: "Kadeh",
							},
						],
					},
					{
						id: 2217385,
						name: "Tuborg Summertime",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/2217385_38142.jpg?202206041242",
						categoryId: 219801,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 22,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2353590,
							isDefault: true,
							price: 32.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2353590,
								isDefault: true,
								price: 32.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
					{
						id: 2228200,
						name: "Blanc",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/2228200_38142.jpg?202206041240",
						categoryId: 219801,
						taxRate: 18.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 23,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2365032,
							isDefault: true,
							price: 48.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2365032,
								isDefault: true,
								price: 48.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
					{
						id: 2245326,
						name: "Honey Viski Jack ",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/2245326_38142.jpg?202206041240",
						categoryId: 219801,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 24,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2383654,
							isDefault: true,
							price: 70.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2383654,
								isDefault: true,
								price: 70.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
					{
						id: 2252499,
						name: "Carlsberg Luna",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/2252499_38142.jpg?202206060207",
						categoryId: 219801,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 25,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2391294,
							isDefault: true,
							price: 36.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2391294,
								isDefault: true,
								price: 36.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
					{
						id: 2267607,
						name: "Jagger Shot",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219801,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 26,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2408306,
							isDefault: true,
							price: 50.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2408306,
								isDefault: true,
								price: 50.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
					{
						id: 2267608,
						name: "Ginn Shot",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219801,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 27,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2408307,
							isDefault: true,
							price: 30.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2408307,
								isDefault: true,
								price: 30.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
				],
			},
			{
				id: 219802,
				name: "İçecekler",
				sortNo: 8,
				photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/category.png",
				categoryPhoto: null,
				products: [
					{
						id: 2179856,
						name: "Coca-Cola Zero",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/2179856_38142.jpg?202205230249",
						categoryId: 219802,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: true,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312296,
							isDefault: true,
							price: 14.0,
							unitId: 30623,
							unitName: "Kutu",
						},
						productUnits: [
							{
								id: 2312296,
								isDefault: true,
								price: 14.0,
								unitId: 30623,
								unitName: "Kutu",
							},
						],
					},
					{
						id: 2179857,
						name: "Coca-Cola",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/2179857_38142.jpg?202205230249",
						categoryId: 219802,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: false,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312297,
							isDefault: true,
							price: 14.0,
							unitId: 30623,
							unitName: "Kutu",
						},
						productUnits: [
							{
								id: 2312297,
								isDefault: true,
								price: 14.0,
								unitId: 30623,
								unitName: "Kutu",
							},
						],
					},
					{
						id: 2179858,
						name: "Fanta",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/2179858_38142.jpg?202205230250",
						categoryId: 219802,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312298,
							isDefault: true,
							price: 14.0,
							unitId: 30623,
							unitName: "Kutu",
						},
						productUnits: [
							{
								id: 2312298,
								isDefault: true,
								price: 14.0,
								unitId: 30623,
								unitName: "Kutu",
							},
						],
					},
					{
						id: 2179860,
						name: "Sprite",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/2179860_38142.jpg?202205230251",
						categoryId: 219802,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312300,
							isDefault: true,
							price: 14.0,
							unitId: 30623,
							unitName: "Kutu",
						},
						productUnits: [
							{
								id: 2312300,
								isDefault: true,
								price: 14.0,
								unitId: 30623,
								unitName: "Kutu",
							},
						],
					},
					{
						id: 2179862,
						name: "Fuse Tea Karpuz",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/2179862_38142.jpg?202205230254",
						categoryId: 219802,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312302,
							isDefault: true,
							price: 14.0,
							unitId: 30623,
							unitName: "Kutu",
						},
						productUnits: [
							{
								id: 2312302,
								isDefault: true,
								price: 14.0,
								unitId: 30623,
								unitName: "Kutu",
							},
						],
					},
					{
						id: 2179864,
						name: "Niğde Gazozu",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/2179864_38142.jpg?202205230255",
						categoryId: 219802,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312304,
							isDefault: true,
							price: 10.0,
							unitId: 30624,
							unitName: "Şişe",
						},
						productUnits: [
							{
								id: 2312304,
								isDefault: true,
								price: 10.0,
								unitId: 30624,
								unitName: "Şişe",
							},
						],
					},
					{
						id: 2179866,
						name: "Ayran",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/2179866_38142.jpg?202205230255",
						categoryId: 219802,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312306,
							isDefault: true,
							price: 10.0,
							unitId: 30624,
							unitName: "Şişe",
						},
						productUnits: [
							{
								id: 2312306,
								isDefault: true,
								price: 10.0,
								unitId: 30624,
								unitName: "Şişe",
							},
						],
					},
					{
						id: 2179867,
						name: "Soda",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/2179867_38142.jpg?202205230258",
						categoryId: 219802,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312307,
							isDefault: true,
							price: 10.0,
							unitId: 30624,
							unitName: "Şişe",
						},
						productUnits: [
							{
								id: 2312307,
								isDefault: true,
								price: 10.0,
								unitId: 30624,
								unitName: "Şişe",
							},
						],
					},
					{
						id: 2179869,
						name: "Limonlu Soda",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/2179869_38142.jpg?202205230258",
						categoryId: 219802,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312309,
							isDefault: true,
							price: 12.0,
							unitId: 30624,
							unitName: "Şişe",
						},
						productUnits: [
							{
								id: 2312309,
								isDefault: true,
								price: 12.0,
								unitId: 30624,
								unitName: "Şişe",
							},
						],
					},
					{
						id: 2179870,
						name: "Su",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/2179870_38142.jpg?202205230301",
						categoryId: 219802,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312310,
							isDefault: true,
							price: 6.0,
							unitId: 30624,
							unitName: "Şişe",
						},
						productUnits: [
							{
								id: 2312310,
								isDefault: true,
								price: 6.0,
								unitId: 30624,
								unitName: "Şişe",
							},
						],
					},
					{
						id: 2179872,
						name: "Red Bull",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/2179872_38142.jpg?202205230300",
						categoryId: 219802,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312312,
							isDefault: true,
							price: 27.0,
							unitId: 30623,
							unitName: "Kutu",
						},
						productUnits: [
							{
								id: 2312312,
								isDefault: true,
								price: 27.0,
								unitId: 30623,
								unitName: "Kutu",
							},
						],
					},
					{
						id: 2199127,
						name: "Churchill ",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/2199127_38142.jpg?202205230300",
						categoryId: 219802,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 11,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2333736,
							isDefault: true,
							price: 16.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2333736,
								isDefault: true,
								price: 16.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
					{
						id: 2267606,
						name: "Buzlu Limonlu Bardak ",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219802,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 12,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2408305,
							isDefault: true,
							price: 3.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2408305,
								isDefault: true,
								price: 3.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
					{
						id: 2366407,
						name: "Fuse Tea Şeftali",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/2366407_38142.jpg?202207070647",
						categoryId: 219802,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 13,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2515256,
							isDefault: true,
							price: 14.0,
							unitId: 30623,
							unitName: "Kutu",
						},
						productUnits: [
							{
								id: 2515256,
								isDefault: true,
								price: 14.0,
								unitId: 30623,
								unitName: "Kutu",
							},
						],
					},
					{
						id: 2366409,
						name: "Fuse Tea Limon",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/2366409_38142.jpg?202207070649",
						categoryId: 219802,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 14,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2515258,
							isDefault: true,
							price: 14.0,
							unitId: 30623,
							unitName: "Kutu",
						},
						productUnits: [
							{
								id: 2515258,
								isDefault: true,
								price: 14.0,
								unitId: 30623,
								unitName: "Kutu",
							},
						],
					},
					{
						id: 2366413,
						name: "Fuse Tea Mango ve Ananas",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/2366413_38142.jpg?202207070650",
						categoryId: 219802,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 16,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2515262,
							isDefault: true,
							price: 14.0,
							unitId: 30623,
							unitName: "Kutu",
						},
						productUnits: [
							{
								id: 2515262,
								isDefault: true,
								price: 14.0,
								unitId: 30623,
								unitName: "Kutu",
							},
						],
					},
					{
						id: 2366605,
						name: "Coca-Cola Light",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/2366605_38142.jpg?202207070651",
						categoryId: 219802,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: true,
						sortNo: 16,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2515455,
							isDefault: true,
							price: 14.0,
							unitId: 30623,
							unitName: "Kutu",
						},
						productUnits: [
							{
								id: 2515455,
								isDefault: true,
								price: 14.0,
								unitId: 30623,
								unitName: "Kutu",
							},
						],
					},
				],
			},
			{
				id: 219803,
				name: "Refreshing Drinks",
				sortNo: 9,
				photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/category.png",
				categoryPhoto: null,
				products: [
					{
						id: 2179667,
						name: "Milk Shake",
						description: "Vanilya, Çilek, Mavi Karamel, Muz, Çikolata",
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219803,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312099,
							isDefault: true,
							price: 30.0,
							unitId: 30618,
							unitName: "43cl",
						},
						productUnits: [
							{
								id: 2312099,
								isDefault: true,
								price: 30.0,
								unitId: 30618,
								unitName: "43cl",
							},
						],
					},
					{
						id: 2179881,
						name: "Cool Berry",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/2179881_38142.jpg?202205260118",
						categoryId: 219803,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312321,
							isDefault: true,
							price: 28.0,
							unitId: 30618,
							unitName: "43cl",
						},
						productUnits: [
							{
								id: 2312321,
								isDefault: true,
								price: 28.0,
								unitId: 30618,
								unitName: "43cl",
							},
						],
					},
					{
						id: 2179882,
						name: "Cool Lime",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/2179882_38142.jpg?202205260119",
						categoryId: 219803,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312322,
							isDefault: true,
							price: 28.0,
							unitId: 30618,
							unitName: "43cl",
						},
						productUnits: [
							{
								id: 2312322,
								isDefault: true,
								price: 28.0,
								unitId: 30618,
								unitName: "43cl",
							},
						],
					},
					{
						id: 2179885,
						name: "Frozen",
						description: "Karpuz, Böğürtlen aromalı",
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219803,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312325,
							isDefault: true,
							price: 28.0,
							unitId: 30618,
							unitName: "43cl",
						},
						productUnits: [
							{
								id: 2312325,
								isDefault: true,
								price: 28.0,
								unitId: 30618,
								unitName: "43cl",
							},
						],
					},
				],
			},
			{
				id: 219804,
				name: "Kokteyl Bar",
				sortNo: 10,
				photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/category.png",
				categoryPhoto: null,
				products: [
					{
						id: 2179889,
						name: "Margarita",
						description: "Tekila, Cointreau, Fresj limon suyu",
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/2179889_38142.jpg?202205260116",
						categoryId: 219804,
						taxRate: 18.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312329,
							isDefault: true,
							price: 60.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2312329,
								isDefault: true,
								price: 60.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
					{
						id: 2179891,
						name: "Mojito",
						description: "Bacardi, taze nane, soda, limon suyu, esmer şeker",
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219804,
						taxRate: 18.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312331,
							isDefault: true,
							price: 60.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2312331,
								isDefault: true,
								price: 60.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
					{
						id: 2267604,
						name: "Sex On The Beach",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219804,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 3,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2408303,
							isDefault: true,
							price: 60.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2408303,
								isDefault: true,
								price: 60.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
					{
						id: 2267605,
						name: "Cosmopolitan",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219804,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 4,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2408304,
							isDefault: true,
							price: 60.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2408304,
								isDefault: true,
								price: 60.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
					{
						id: 2338486,
						name: "Bery Mix",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219804,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 4,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2484613,
							isDefault: true,
							price: 60.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2484613,
								isDefault: true,
								price: 60.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
					{
						id: 2338487,
						name: "Lime Mix",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219804,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 5,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2484614,
							isDefault: true,
							price: 60.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2484614,
								isDefault: true,
								price: 60.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
				],
			},
			{
				id: 219805,
				name: "Kahve ve Sıcak İçecekler",
				sortNo: 11,
				photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/category.png",
				categoryPhoto: null,
				products: [
					{
						id: 2179916,
						name: "Espresso",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219805,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312356,
							isDefault: true,
							price: 15.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2312356,
								isDefault: true,
								price: 15.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
					{
						id: 2179917,
						name: "Americano",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219805,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312357,
							isDefault: true,
							price: 17.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2312357,
								isDefault: true,
								price: 17.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
					{
						id: 2179919,
						name: "Latte",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219805,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312359,
							isDefault: true,
							price: 21.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2312359,
								isDefault: true,
								price: 21.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
					{
						id: 2179921,
						name: "Cappuccino",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219805,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312361,
							isDefault: true,
							price: 21.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2312361,
								isDefault: true,
								price: 21.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
					{
						id: 2179924,
						name: "Filtre Kahve",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219805,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312364,
							isDefault: true,
							price: 20.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2312364,
								isDefault: true,
								price: 20.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
					{
						id: 2179926,
						name: "Latte Macchiato",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219805,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312366,
							isDefault: true,
							price: 23.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2312366,
								isDefault: true,
								price: 23.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
					{
						id: 2179927,
						name: "Iced Americano",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219805,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312367,
							isDefault: true,
							price: 22.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2312367,
								isDefault: true,
								price: 22.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
					{
						id: 2179928,
						name: "Iced Latte",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219805,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312368,
							isDefault: true,
							price: 25.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2312368,
								isDefault: true,
								price: 25.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
					{
						id: 2179930,
						name: "Iced Latte Macchiato",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219805,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312370,
							isDefault: true,
							price: 27.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2312370,
								isDefault: true,
								price: 27.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
					{
						id: 2179933,
						name: "Iced Cappuccino",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219805,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312373,
							isDefault: true,
							price: 25.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2312373,
								isDefault: true,
								price: 25.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
					{
						id: 2179934,
						name: "Türk Kahvesi",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219805,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312374,
							isDefault: true,
							price: 15.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2312374,
								isDefault: true,
								price: 15.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
					{
						id: 2179935,
						name: "Damla Sakızlı Türk Kahvesi",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219805,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312375,
							isDefault: true,
							price: 15.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2312375,
								isDefault: true,
								price: 15.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
					{
						id: 2179937,
						name: "Sütlü Türk Kahvesi",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219805,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312377,
							isDefault: true,
							price: 15.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2312377,
								isDefault: true,
								price: 15.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
					{
						id: 2179938,
						name: "Çay",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219805,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312378,
							isDefault: true,
							price: 5.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2312378,
								isDefault: true,
								price: 5.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
					{
						id: 2179939,
						name: "Kış Çayı",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219805,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312379,
							isDefault: true,
							price: 13.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2312379,
								isDefault: true,
								price: 13.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
					{
						id: 2179940,
						name: "Nane Limon",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219805,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312380,
							isDefault: true,
							price: 13.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2312380,
								isDefault: true,
								price: 13.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
					{
						id: 2179941,
						name: "Yeşil Çay",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219805,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312381,
							isDefault: true,
							price: 13.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2312381,
								isDefault: true,
								price: 13.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
					{
						id: 2179942,
						name: "Ada Çayı",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219805,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312382,
							isDefault: true,
							price: 13.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2312382,
								isDefault: true,
								price: 13.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
					{
						id: 2179943,
						name: "Ihlamur",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 219805,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2312383,
							isDefault: true,
							price: 13.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2312383,
								isDefault: true,
								price: 13.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
				],
			},
			{
				id: 221132,
				name: "Çerezler",
				sortNo: 12,
				photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/category.png",
				categoryPhoto: null,
				products: [
					{
						id: 2194380,
						name: "Bira Karışık",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 221132,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2328368,
							isDefault: true,
							price: 20.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2328368,
								isDefault: true,
								price: 20.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
					{
						id: 2194382,
						name: "Tuzlu Fıstık",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 221132,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 0,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2328370,
							isDefault: true,
							price: 15.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2328370,
								isDefault: true,
								price: 15.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
					{
						id: 2194417,
						name: "Atolye Lux",
						description: null,
						photoUrl: "https://adisyostorage.blob.core.windows.net/mahrezphotos/product.png",
						categoryId: 221132,
						taxRate: 8.0,
						isFree: false,
						isUseCompositeProduct: null,
						isUseProductMenu: null,
						isUseProductFeature: null,
						featureSelectionIsMandatory: null,
						sortNo: 2,
						isTaxExcluded: null,
						defaultProductUnit: {
							id: 2328410,
							isDefault: true,
							price: 30.0,
							unitId: 4,
							unitName: "Tam",
						},
						productUnits: [
							{
								id: 2328410,
								isDefault: true,
								price: 30.0,
								unitId: 4,
								unitName: "Tam",
							},
						],
					},
				],
			},
		],
	},
];

export function getMenuById(id) {
	return menu.find((m) => m.id === id);
}
