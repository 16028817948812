export default {
  "data": {
    "restaurant": {
      "id": "605cb68c65ce68464daa6818",
      "name": "Burger King (Bahriye Üçok Mah.)",
      "slug": "burger-king-bahriye-ucok-mah-karsiyaka-izmir",
      "imageURL": "https://cdn.getiryemek.com/restaurants/1618309789015_1125x522.jpeg",
      "ratingPoint": 3.9,
      "ratingCount": "(900+)",
      "isFavorite": false,
      "cuisines": [
        {
          "id": "5c5429bb2857994ab884dc36",
          "name": "Burger",
          "imageURL": "https://cdn.getiryemek.com/cuisines/1619220348726_480x300.jpeg"
        }
      ],
      "openClosedTime": "Kapanış 00:45",
      "openingClosingDate": "Açılış 11:00 - Kapanış 00:45",
      "isReviewEnabled": false,
      "paymentOptions": [
        {
          "title": "Online Ödeme",
          "imageURL": "https://cdn.getiryemek.com/ondelivery/icon-card.png",
          "isActive": true
        },
        {
          "imageURL": "https://cdn.getiryemek.com/ondelivery/icon-card.png",
          "title": "Kredi / Banka Kartı",
          "isActive": true
        },
        {
          "imageURL": "https://cdn.getiryemek.com/ondelivery/icon-cash.png",
          "title": "Nakit",
          "isActive": true
        },
        {
          "imageURL": "https://cdn.getiryemek.com/ondelivery/icon-multinet.png",
          "title": "Multinet Kartı",
          "isActive": false
        },
        {
          "imageURL": "https://cdn.getiryemek.com/ondelivery/icon-sodexo.png",
          "title": "Sodexo Kartı",
          "isActive": false
        },
        {
          "imageURL": "https://cdn.getiryemek.com/ondelivery/icon-ticket.png",
          "title": "Ticket Kartı",
          "isActive": false
        },
        {
          "imageURL": "https://cdn.getiryemek.com/ondelivery/icon-metropol.png",
          "title": "Metropol Kartı",
          "isActive": true
        },
        {
          "imageURL": "https://cdn.getiryemek.com/ondelivery/icon-paye.png",
          "title": "Paye Kart",
          "isActive": false
        }
      ],
      "infoItems": [

      ],
      "workingHours": [
        {
          "title": "Pazartesi",
          "value": "11:00 - 00:45"
        },
        {
          "title": "Salı",
          "value": "11:00 - 00:45"
        },
        {
          "title": "Çarşamba",
          "value": "11:00 - 00:45"
        },
        {
          "title": "Perşembe",
          "value": "11:00 - 00:45"
        },
        {
          "title": "Cuma",
          "value": "11:00 - 00:45"
        },
        {
          "title": "Cumartesi",
          "value": "11:00 - 00:45"
        },
        {
          "title": "Pazar",
          "value": "11:00 - 00:45"
        }
      ],
      "restaurantRatings": {
        "restaurant": "605cb68c65ce68464daa6818",
        "restaurantRating": 3.917,
        "restaurantRatingCount": 2761,
        "courierRating": 4.263,
        "courierRatingCount": 2638,
        "restaurantRatingScore": {
          "fiveStarCount": 1223,
          "fourStarCount": 537,
          "threeStarCount": 349,
          "twoStarCount": 145,
          "oneStarCount": 507
        },
        "restaurantRatingHistory": {
          "thisWeek": 4.2,
          "lastWeek": 3.953,
          "twoWeeksBefore": 4.198,
          "threeWeeksBefore": 4.099,
          "fourWeeksBefore": 4.05
        },
        "courierRatingScore": {
          "fiveStarCount": 1760,
          "fourStarCount": 433,
          "threeStarCount": 163,
          "twoStarCount": 39,
          "oneStarCount": 243
        },
        "totalReviewCount": 161
      },
      "deliveryFee": "₺ 6,90",
      "minBasketSize": {
        "text": "Min.",
        "value": "₺ 25,00"
      },
      "restaurantMinBasketSize": {
        "text": "Min.",
        "value": "₺ 25,00"
      },
      "badges": [

      ]
    },
    "productCategories": [
      {
        "id": "5e95c02f8b6ee51f65657094",
        "name": "En Sevilenler",
        "products": [
          {
            "id": "60648fdcd8d0a2f4d93e1545",
            "name": "Benim İkilim",
            "priceText": "₺ 34,99",
            "price": 34.99,
            "description": "2 Adet Tercih Edeceğiniz Sandviç, Büyük Boy Patates ve 1 L İçecek (Tavukburger® seçimleri için geçerlidir. King Chicken®, Big King Jr.® seçimlerinde 3 TL, Chicken Royale 5 TL, Big King® seçimlerinde 9,5 TL fark alınmaktadır.)",
            "imageURL": "https://cdn.getiryemek.com/products/1623319115418_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1623319115418_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "60d497b6cb32e587deb2660e",
            "name": "Kral İkili",
            "priceText": "₺ 43,99",
            "price": 43.99,
            "description": "Big King® Sandviç, King Chicken® Sandviç, Büyük Boy Patates ve 1 L İçecek",
            "imageURL": "https://cdn.getiryemek.com/products/1625142795884_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1625142795884_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "60645689d813fb54c26f03bc",
            "name": "Big King® Menü",
            "priceText": "₺ 33,50",
            "price": 33.5,
            "description": "Big King®, Büyük Boy Patates ve Kutu İçecek",
            "imageURL": "https://cdn.getiryemek.com/products/1623327056988_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1623327056988_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "60643fe29c554005ffb4168f",
            "name": "Whopper® Menü",
            "priceText": "₺ 37,50",
            "price": 37.5,
            "description": "Whopper® Sandviç, Büyük Boy Patates ve Kutu İçecek",
            "imageURL": "https://cdn.getiryemek.com/products/1623328212506_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1623328212506_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "606448bf10a3e571d04f93f1",
            "name": "BK Steakhouse Burger® Menü",
            "priceText": "₺ 39,99",
            "price": 39.99,
            "description": "BK Steakhouse Burger®, Büyük Boy Patates ve Kutu İçecek",
            "imageURL": "https://cdn.getiryemek.com/products/1623328156519_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1623328156519_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          }
        ]
      },
      {
        "id": "60eebcfc6531ca3f0982f43f",
        "name": "GetirYemek'e Özel Menü",
        "products": [
          {
            "id": "60eebe66a7ff3eec199367e9",
            "name": "Sıcak Fırsat",
            "priceText": "₺ 39,95",
            "price": 39.95,
            "struckPriceText": "₺ 51,09",
            "struckPrice": 51.09,
            "description": "Chicken Royale®, Köfteburger, Orta Boy Patates, Soğan Halkaları (4'lü) ve 1 L İçecek",
            "imageURL": "https://cdn.getiryemek.com/products/1626675171457_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1626675171457_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          }
        ]
      },
      {
        "id": "606422706fe6162d4ae29ba3",
        "name": "Kampanyalı Menü",
        "products": [
          {
            "id": "60d497b6cb32e587deb2660e",
            "name": "Kral İkili",
            "priceText": "₺ 43,99",
            "price": 43.99,
            "description": "Big King® Sandviç, King Chicken® Sandviç, Büyük Boy Patates ve 1 L İçecek",
            "imageURL": "https://cdn.getiryemek.com/products/1625142795884_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1625142795884_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "60c1ccb240dfe06fd6d962ea",
            "name": "Lezzet Kovalı Maç Menüsü",
            "priceText": "₺ 51,99",
            "price": 51.99,
            "description": "2 Adet Whopper Jr.®, 6’lı BK King Nuggets, Büyük Boy Patates, Soğan Halkaları (8'li), 4‘lü Chicken Tenders ve 1 L İçecek",
            "imageURL": "https://cdn.getiryemek.com/products/1623319299405_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1623319299405_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6080b0eda562d4c2fdf2a080",
            "name": "Karışık Lezzet Kova Menü",
            "priceText": "₺ 31,99",
            "price": 31.99,
            "description": "6’lı BK King Nuggets, 8’li Soğan Halkası, 4 ‘lü Chicken Tenders, Büyük Patates, Litrelik İçecek",
            "imageURL": "https://cdn.getiryemek.com/products/1623319227416_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1623319227416_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "608134e2748155222be0e091",
            "name": "Karışık Lezzet Kovası",
            "priceText": "₺ 22,99",
            "price": 22.99,
            "description": "4’lü Nuggets, 8’li Soğan Halkası, 4’lü Tenders, Orta Patates",
            "imageURL": "https://cdn.getiryemek.com/products/1622196204264_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1622196204264_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "60648fdcd8d0a2f4d93e1545",
            "name": "Benim İkilim",
            "priceText": "₺ 34,99",
            "price": 34.99,
            "description": "2 Adet Tercih Edeceğiniz Sandviç, Büyük Boy Patates ve 1 L İçecek (Tavukburger® seçimleri için geçerlidir. King Chicken®, Big King Jr.® seçimlerinde 3 TL, Chicken Royale 5 TL, Big King® seçimlerinde 9,5 TL fark alınmaktadır.)",
            "imageURL": "https://cdn.getiryemek.com/products/1623319115418_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1623319115418_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6064837bf2765eda029a0780",
            "name": "Pro Gamer Big King® Menü",
            "priceText": "₺ 37,99",
            "price": 37.99,
            "description": "Big King®, Büyük Boy Patates, Soğan Halkaları (4'lü), Litrelik İçecek",
            "imageURL": "https://cdn.getiryemek.com/products/1623319310445_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1623319310445_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "606484bb8843fe64b07d735d",
            "name": "Diamond Gamer Menü",
            "priceText": "₺ 33,99",
            "price": 33.99,
            "description": "Whopper Jr.® veya Chicken Royale®, Büyük Boy Patates, Soğan Halkaları (4'lü), 4'lü BK King Nuggets® ve Litrelik İçecek",
            "imageURL": "https://cdn.getiryemek.com/products/1623673029080_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1623673029080_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "606481d9e81f5bf12f87b56d",
            "name": "Pro Gamer Tavuklu BBQ Deluxe Menü",
            "priceText": "₺ 36,99",
            "price": 36.99,
            "description": "Tavuklu Barbekü Deluxe Sandviç, Tavuklu Barbekü Deluxe Sandviç, Büyük Boy Patates, Litrelik İçecek",
            "imageURL": "https://cdn.getiryemek.com/products/1623319188370_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1623319188370_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "60648d201a40c462343bea0f",
            "name": "Burger King® Algida Menüsü 2",
            "priceText": "₺ 49,99",
            "price": 49.99,
            "description": "2 Adet Chicken Royale®, Büyük Boy Patates, 1 L İçecek ve 2 Adet Algida Mini Nogger (Sadece Chicken Royale® ve Etli Barbekü Deluxe Burger seçimleri için geçerlidir. BK Kral Burger seçimlerinde 6,50 TL, Whopper® seçimlerinde 9 TL fark alınmaktadır.)",
            "imageURL": "https://cdn.getiryemek.com/products/1623319105920_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1623319105920_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "606471e6b48e846b84453d8f",
            "name": "Kral Teklif Menü",
            "priceText": "₺ 49,99",
            "price": 49.99,
            "description": "BK Kral Burger, Whopper Jr.®, Büyük Boy Patates, Soğan Halkaları (8'li) ve 1 L İçecek",
            "imageURL": "https://cdn.getiryemek.com/products/1623319273944_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1623319273944_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "60648ab5c271ae765c2fb83b",
            "name": "Kral Menü",
            "priceText": "₺ 49,50",
            "price": 49.5,
            "description": "3 Adet Tercih Edeceğiniz Burger, Büyük Boy Patates, Soğan Halkaları (8'li) ve Coca-Cola Şekersiz (1 L) (Sadece King Chicken® seçimleri için geçerlidir. Köfteburger® seçimlerinde 1 TL, Chicken Royale® seçimlerinde 4 TL, BK Kral Burger seçimlerinde 9,00 TL, Whopper ® 12,50 TL fark alınmaktadır.)",
            "imageURL": "https://cdn.getiryemek.com/products/1623319170423_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1623319170423_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6064977782a0d0616115b915",
            "name": "3'lü Whopper® Fırsatı",
            "priceText": "₺ 47,97",
            "price": 47.97,
            "description": "3 Adet Whopper Jr.®, Büyük Boy Patates ve 1 L İçecek (Whopper Jr.® seçimleri için geçerlidir. Whopper® seçimlerinde 10,00 TL, Double Whopper® seçimlerinde 19,99 TL ve Triple Whopper® seçimlerinde 26,99 TL fark alınmaktadır.)",
            "imageURL": "https://cdn.getiryemek.com/products/1623319002589_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1623319002589_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "60649d601d92b241ee7565cc",
            "name": "Dörtlü Fırsat Menüsü",
            "priceText": "₺ 64,99",
            "price": 64.99,
            "description": "4 Adet King Chicken®, King Boy Patates Kızartması, Soğan Halkaları (12'li) ve 1 L İçecek",
            "imageURL": "https://cdn.getiryemek.com/products/1623319151062_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1623319151062_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "606491a806714e54cd68cb6b",
            "name": "Kral Fırsat",
            "priceText": "₺ 79,99",
            "price": 79.99,
            "description": "2 Adet Big King®️,  2 Adet King Chicken®️, King Boy Patates Kızartması ve Soğan Halkaları (8'li) ve 1 L İçecek",
            "imageURL": "https://cdn.getiryemek.com/products/1623319243317_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1623319243317_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "60649b252eb439cb4eb7d138",
            "name": "5'li Whopper® Fırsatı",
            "priceText": "₺ 79,95",
            "price": 79.95,
            "description": "5 Adet Whopper Jr.®, 2 adet Büyük Boy Patates, Soğan Halkaları (10'lu) ve 1 L İçecek (Whopper Jr.® seçimleri için geçerlidir. Whopper® seçimlerinde 10,00 TL, Double Whopper® seçimlerinde 19,99 TL ve Triple Whopper® seçimlerinde 26,99 TL fark alınmaktadır.)",
            "imageURL": "https://cdn.getiryemek.com/products/1624399482516_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1624399482516_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "60649f63f0248b1f15de453c",
            "name": "Dört Dörtlük Menü",
            "priceText": "₺ 119,96",
            "price": 119.96,
            "description": "2 Adet Whopper®, 2 Adet Klasik Gurme Tavuk®, 2 Adet King Boy Patates, Soğan Halkaları (12'li), 2 Adet 1 L İçecek (Sadece Whopper® ve Klasik Gurme Tavuk® seçimleri için geçerlidir. BK Steakhouse Burger® seçimlerinde 5 TL, Texas Smokehouse Burger® ve Mega Double Cheeseburger seçimlerinde 7,50 TL, Big King XXL® seçimlerinde 10 TL fark alınmaktadır.)",
            "imageURL": "https://cdn.getiryemek.com/products/1623319140540_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1623319140540_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "606469c78a29fa828a2f4fc0",
            "name": "Çıtır Çıtır Atıştır",
            "priceText": "₺ 10,99",
            "price": 10.99,
            "description": "Çıtır Peynir (2'li), BK King Nuggets® (2'li), Soğan Halkaları (4'lü)",
            "imageURL": "https://cdn.getiryemek.com/products/1618056791835_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618056791835_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "606c7227ade74fe64ac7b137",
            "name": "Burger King® Algida Menüsü",
            "priceText": "₺ 49,99",
            "price": 49.99,
            "description": "2 Adet Tavukburger®, Büyük Boy Patates, 2 Adet Algida Magnum Mini Cookie (58 ml) ve 1 L İçecek",
            "imageURL": "https://cdn.getiryemek.com/products/1623319071326_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1623319071326_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "60d1ecbf62b7ff282619266d",
            "name": "Doyuran Kral Menü",
            "priceText": "₺ 49,99",
            "price": 49.99,
            "description": "Big King®, Whopper Jr.®, Orta Boy Patates, Soğan Halkaları (4'lü), 2 adet Algida Maraş Usulü Cup Dondurma (100 ml) ve 1 L İçecek",
            "imageURL": "https://cdn.getiryemek.com/products/1624370442515_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1624370442515_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          }
        ]
      },
      {
        "id": "606422882b0371ddb7dcaffa",
        "name": "Lezzetler Ligi",
        "products": [
          {
            "id": "606476cbf4083e9926891629",
            "name": "Beşiktaş Menüsü",
            "priceText": "₺ 56,99",
            "price": 56.99,
            "description": "Big King®, Big King®, Büyük Boy Patates, Soğan Halkaları (10'lu) ve 1 L İçecek (Taşıma torbası seçimi siparişinizin doğru şekilde teslim edilebilmesi için eklenmiştir.)",
            "imageURL": "https://cdn.getiryemek.com/products/1623319397089_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1623319397089_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6064750fbf03aaeee632ecf3",
            "name": "Galatasaray Menüsü",
            "priceText": "₺ 56,99",
            "price": 56.99,
            "description": "Big King®, Big King®, Büyük Boy Patates, Soğan Halkaları (10'lu) ve 1 L İçecek (Taşıma torbası seçimi siparişinizin doğru şekilde teslim edilebilmesi için eklenmiştir.)",
            "imageURL": "https://cdn.getiryemek.com/products/1623319404883_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1623319404883_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "606476356c2d471ae3511efe",
            "name": "Fenerbahçe Menüsü",
            "priceText": "₺ 56,99",
            "price": 56.99,
            "description": "Big King®, Big King®, Büyük Boy Patates, Soğan Halkaları (10'lu) ve 1 L İçecek (Taşıma torbası seçimi siparişinizin doğru şekilde teslim edilebilmesi için eklenmiştir.)",
            "imageURL": "https://cdn.getiryemek.com/products/1623319425077_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1623319425077_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6064777b81f1143c4923aa15",
            "name": "Trabzonspor Menüsü",
            "priceText": "₺ 56,99",
            "price": 56.99,
            "description": "Big King®, Big King®, Büyük Boy Patates, Soğan Halkaları (10'lu) ve 1 L İçecek (Taşıma torbası seçimi siparişinizin doğru şekilde teslim edilebilmesi için eklenmiştir.)",
            "imageURL": "https://cdn.getiryemek.com/products/1623319442563_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1623319442563_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          }
        ]
      },
      {
        "id": "60642290d79a7946f1499d7b",
        "name": "Menü",
        "products": [
          {
            "id": "6074c5f3ad8e734238ad534a",
            "name": "Pastırmalı Whopper® Menü",
            "priceText": "₺ 40,50",
            "price": 40.5,
            "description": "Pastırmalı  Whopper® Sandviç, Büyük Boy Patates ve Kutu İçecek",
            "imageURL": "https://cdn.getiryemek.com/products/1623328116022_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1623328116022_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "60643d2d4b04758b395b2c47",
            "name": "Big King®Jr. Menü",
            "priceText": "₺ 28,50",
            "price": 28.5,
            "description": "Big King® Jr., Büyük Boy Patates ve Kutu İçecek",
            "imageURL": "https://cdn.getiryemek.com/products/1623327035138_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1623327035138_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "60642f471a40c4e2013a40df",
            "name": "BK Kral Burger Menü",
            "priceText": "₺ 34,99",
            "price": 34.99,
            "description": "BK Kral Burger, Büyük Boy Patates ve Kutu İçecek",
            "imageURL": "https://cdn.getiryemek.com/products/1623328051644_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1623328051644_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "60643ca4d79a79616f4a1fff",
            "name": "Mangalda Izgara Tavuk Menü",
            "priceText": "₺ 34,99",
            "price": 34.99,
            "description": "Mangalda Izgara Tavuk Sandviç, Büyük Boy Patates ve Kutu İçecek",
            "imageURL": "https://cdn.getiryemek.com/products/1623328933670_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1623328933670_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "60643fe29c554005ffb4168f",
            "name": "Whopper® Menü",
            "priceText": "₺ 37,50",
            "price": 37.5,
            "description": "Whopper® Sandviç, Büyük Boy Patates ve Kutu İçecek",
            "imageURL": "https://cdn.getiryemek.com/products/1623328212506_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1623328212506_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "60643f184b0475dc965b856a",
            "name": "Etli Master Burger Menü",
            "priceText": "₺ 39,99",
            "price": 39.99,
            "description": "Etli Master Burger, Büyük Boy Patates ve Kutu İçecek",
            "imageURL": "https://cdn.getiryemek.com/products/1623327889786_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1623327889786_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "60643bf6bf03aadd48327ee7",
            "name": "Tavuklu Master Burger Menü",
            "priceText": "₺ 35,99",
            "price": 35.99,
            "description": "Tavuklu Master Burger, Büyük Boy Patates ve Kutu İçecek",
            "imageURL": "https://cdn.getiryemek.com/products/1623329060458_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1623329060458_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6064408865ce68b035acf0b2",
            "name": "Double Whopper® Menü",
            "priceText": "₺ 46,99",
            "price": 46.99,
            "description": "Double Whopper®, Büyük Boy Patates ve Kutu İçecek",
            "imageURL": "https://cdn.getiryemek.com/products/1623327329352_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1623327329352_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "60643deb7692c745b3fd3560",
            "name": "Double Big King® Menü",
            "priceText": "₺ 47,99",
            "price": 47.99,
            "description": "Double Big King®, Büyük Boy Patates ve Kutu İçecek",
            "imageURL": "https://cdn.getiryemek.com/products/1623327153066_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1623327153066_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "606444fc3730cf0f49542dfd",
            "name": "Whopper Jr.® Menü",
            "priceText": "₺ 28,50",
            "price": 28.5,
            "description": "Whopper Jr.®, Büyük Boy Patates ve Kutu İçecek",
            "imageURL": "https://cdn.getiryemek.com/products/1623328265122_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1623328265122_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "606445852f111e7b7a8e6760",
            "name": "Chicken Royale® Menü",
            "priceText": "₺ 28,99",
            "price": 28.99,
            "description": "Chicken Royale®, Büyük Boy Patates ve Kutu İçecek",
            "imageURL": "https://cdn.getiryemek.com/products/1623328875745_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1623328875745_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "60644623d8d0a2c5c23d3807",
            "name": "Chicken Tenders® Menü 6'lı",
            "priceText": "₺ 26,99",
            "price": 26.99,
            "description": "Chicken Tenders® 6’lı, Büyük Boy Patates ve Kutu İçecek",
            "imageURL": "https://cdn.getiryemek.com/products/1623328811254_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1623328811254_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6064477c0e468ee3d6c86d18",
            "name": "Fish Royale® Menü",
            "priceText": "₺ 37,50",
            "price": 37.5,
            "description": "Fish Royale®, Büyük Boy Patates ve Kutu İçecek",
            "imageURL": "https://cdn.getiryemek.com/products/1623328729135_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1623328729135_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "606448418a29fa2f8d2edba0",
            "name": "Triple Whopper® Menü",
            "priceText": "₺ 56,99",
            "price": 56.99,
            "description": "Triple Whopper®, Büyük Boy Patates ve Kutu İçecek",
            "imageURL": "https://cdn.getiryemek.com/products/1623328189478_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1623328189478_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "606448bf10a3e571d04f93f1",
            "name": "BK Steakhouse Burger® Menü",
            "priceText": "₺ 39,99",
            "price": 39.99,
            "description": "BK Steakhouse Burger®, Büyük Boy Patates ve Kutu İçecek",
            "imageURL": "https://cdn.getiryemek.com/products/1623328156519_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1623328156519_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "606449322b03719c11dd4d58",
            "name": "Chicken Tenders® Menü 9'lu",
            "priceText": "₺ 29,99",
            "price": 29.99,
            "description": "Chicken Tenders® 9’lu, Büyük Boy Patates ve Kutu İçecek",
            "imageURL": "https://cdn.getiryemek.com/products/1623328849863_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1623328849863_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "606449e6e81f5b1409874adf",
            "name": "King Chicken® Menü",
            "priceText": "₺ 27,99",
            "price": 27.99,
            "description": "King Chicken®, Büyük Boy Patates ve Kutu İçecek",
            "imageURL": "https://cdn.getiryemek.com/products/1618058321526_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618058321526_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "60644a86b71fbc72f41a2794",
            "name": "Double King Chicken® Menü",
            "priceText": "₺ 32,99",
            "price": 32.99,
            "description": "Double King Chicken®, Büyük Boy Patates ve Kutu İçecek",
            "imageURL": "https://cdn.getiryemek.com/products/1623328894396_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1623328894396_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "60644b914c11b03e843c20bb",
            "name": "BK King Nuggets® Menü 6'lı",
            "priceText": "₺ 25,99",
            "price": 25.99,
            "description": "BK King Nuggets® 6’lı, Büyük Boy Patates ve Kutu İçecek",
            "imageURL": "https://cdn.getiryemek.com/products/1623328822247_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1623328822247_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "60644c108a29fa21ed2f3538",
            "name": "BK King Nuggets® Menü 9'lu",
            "priceText": "₺ 29,99",
            "price": 29.99,
            "description": "BK King Nuggets® 9’lu, Büyük Boy Patates ve Kutu İçecek",
            "imageURL": "https://cdn.getiryemek.com/products/1623328863140_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1623328863140_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "60644c804df348de17aaf9d2",
            "name": "Köfteburger® Menü",
            "priceText": "₺ 24,50",
            "price": 24.5,
            "description": "Köfteburger®, Büyük Boy Patates ve Kutu İçecek",
            "imageURL": "https://cdn.getiryemek.com/products/1623328014920_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1623328014920_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "60644e1e9c5540326cb47223",
            "name": "Klasik Gurme Tavuk® Menü",
            "priceText": "₺ 34,99",
            "price": 34.99,
            "description": "Klasik Gurme Tavuk®, Büyük Boy Patates ve Kutu İçecek",
            "imageURL": "https://cdn.getiryemek.com/products/1623328913451_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1623328913451_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "60644eb5d813fb9e456ea8be",
            "name": "Spicy Gurme Tavuk® Menü",
            "priceText": "₺ 34,99",
            "price": 34.99,
            "description": "Spicy Gurme Tavuk®, Büyük Boy Patates ve Kutu İçecek",
            "imageURL": "https://cdn.getiryemek.com/products/1623328943579_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1623328943579_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "60645689d813fb54c26f03bc",
            "name": "Big King® Menü",
            "priceText": "₺ 33,50",
            "price": 33.5,
            "description": "Big King®, Büyük Boy Patates ve Kutu İçecek",
            "imageURL": "https://cdn.getiryemek.com/products/1623327056988_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1623327056988_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "606456f134f5ff2aaa588696",
            "name": "Big King XXL® Menü",
            "priceText": "₺ 49,99",
            "price": 49.99,
            "description": "Big King XXL®, Büyük Boy Patates ve Kutu İçecek",
            "imageURL": "https://cdn.getiryemek.com/products/1623327068517_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1623327068517_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6064575a10a3e56b3b4fef07",
            "name": "Double Cheeseburger Menü",
            "priceText": "₺ 34,99",
            "price": 34.99,
            "description": "Double Cheeseburger, Büyük Boy Patates ve Kutu İçecek",
            "imageURL": "https://cdn.getiryemek.com/products/1623327169286_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1623327169286_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "60645a2682a0d00b0714e790",
            "name": "Texas Smokehouse Burger® Menü",
            "priceText": "₺ 44,99",
            "price": 44.99,
            "description": "Texas Smokehouse Burger®, Büyük Boy Patates ve Kutu İçecek",
            "imageURL": "https://cdn.getiryemek.com/products/1623328171994_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1623328171994_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "60645a98d813fb14496f5de8",
            "name": "Double Texas Smokehouse Burger® Menü",
            "priceText": "₺ 51,99",
            "price": 51.99,
            "description": "Double Texas Smokehouse Burger®, Büyük Boy Patates ve Kutu İçecek",
            "imageURL": "https://cdn.getiryemek.com/products/1623327293239_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1623327293239_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "60645b1969de6c20464d5036",
            "name": "Double Köfteburger® Menü",
            "priceText": "₺ 32,99",
            "price": 32.99,
            "description": "Double Köfteburger®, Büyük Boy Patates ve Kutu İçecek",
            "imageURL": "https://cdn.getiryemek.com/products/1623327246999_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1623327246999_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "60645b850e468e45c2c8c8f3",
            "name": "Tavuklu Barbekü Deluxe Burger Menü",
            "priceText": "₺ 28,99",
            "price": 28.99,
            "description": "Tavuklu Barbekü Deluxe Burger, Büyük Boy Patates ve Kutu İçecek",
            "imageURL": "https://cdn.getiryemek.com/products/1623329026639_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1623329026639_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "60645c323364a4f614a3ffeb",
            "name": "Etli Barbekü Deluxe Burger Menü",
            "priceText": "₺ 28,99",
            "price": 28.99,
            "description": "Etli Barbekü Deluxe Burger, Büyük Boy Patates ve Kutu İçecek",
            "imageURL": "https://cdn.getiryemek.com/products/1623327349453_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1623327349453_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "60645cb0d8d0a26abe3d96e9",
            "name": "Tavuklu Barbekü Brioche® Menü",
            "priceText": "₺ 35,99",
            "price": 35.99,
            "description": "Tavuklu Barbekü Brioche®, Büyük Boy Patates ve Kutu İçecek",
            "imageURL": "https://cdn.getiryemek.com/products/1623329039937_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1623329039937_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "60645dbc2eb4391bb8b6c636",
            "name": "Etli Barbekü Brioche® Menü",
            "priceText": "₺ 38,99",
            "price": 38.99,
            "description": "Etli Barbekü Brioche®, Büyük Boy Patates ve Kutu İçecek",
            "imageURL": "https://cdn.getiryemek.com/products/1623327395991_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1623327395991_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "60645e35f4083e501088b84c",
            "name": "Mega Double Cheeseburger Menü",
            "priceText": "₺ 51,99",
            "price": 51.99,
            "description": "Mega Double Cheeseburger, Büyük Boy Patates ve Kutu İçecek",
            "imageURL": "https://cdn.getiryemek.com/products/1623328076161_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1623328076161_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "60645ea7f2765e4b71999592",
            "name": "Rodeo Whopper® Menü",
            "priceText": "₺ 39,50",
            "price": 39.5,
            "description": "Rodeo Whopper®, Büyük Boy Patates ve Kutu İçecek",
            "imageURL": "https://cdn.getiryemek.com/products/1623328139111_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1623328139111_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "60645f708843fe5c137d0cbb",
            "name": "Tavuk Kanatları Menü 6’lı",
            "priceText": "₺ 31,99",
            "price": 31.99,
            "description": "Tavuk Kanatları 6’lı, Büyük Boy Patates ve Kutu İçecek",
            "imageURL": "https://cdn.getiryemek.com/products/1623328836142_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1623328836142_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "606461731a40c420d43b315f",
            "name": "Hamburger Menü",
            "priceText": "₺ 25,50",
            "price": 25.5,
            "description": "Hamburger, Orta Boy Patates ve Kutu İçecek",
            "imageURL": "https://cdn.getiryemek.com/products/1623327915135_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1623327915135_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6064621d8b0d9715a866b72d",
            "name": "Tavukburger® Menü",
            "priceText": "₺ 22,99",
            "price": 22.99,
            "description": "Tavukburger®, Orta Boy Patates ve Kutu İçecek",
            "imageURL": "https://cdn.getiryemek.com/products/1623328956527_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1623328956527_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "606462b9b48e841ebd44cfc2",
            "name": "Cheeseburger Menü",
            "priceText": "₺ 27,99",
            "price": 27.99,
            "description": "Cheeseburger, Orta Boy Patates ve Kutu İçecek",
            "imageURL": "https://cdn.getiryemek.com/products/1623327088938_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1623327088938_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6064631d24e239740e148f30",
            "name": "Tavukburger® Cheese Menü",
            "priceText": "₺ 24,99",
            "price": 24.99,
            "description": "Tavukburger® Cheese, Orta Boy Patates ve Kutu İçecek",
            "imageURL": "https://cdn.getiryemek.com/products/1623329007844_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1623329007844_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "606c7315507a879ef764ee7b",
            "name": "Fish & Fries Menü",
            "priceText": "₺ 37,50",
            "price": 37.5,
            "description": "Büyük Boy Patates ve Kutu İçecek ile",
            "imageURL": "https://cdn.getiryemek.com/products/1623328718965_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1623328718965_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "60e444cad3050b0528e72bf2",
            "name": "BK Crispy Chicken Menü",
            "priceText": "₺ 27,99",
            "price": 27.99,
            "description": "BK Crispy Chicken, Büyük Boy Patates ve Kutu İçecek",
            "imageURL": "https://cdn.getiryemek.com/products/1625572579143_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1625572579143_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          }
        ]
      },
      {
        "id": "606422791d92b2896274a423",
        "name": "Kids Menü",
        "products": [
          {
            "id": "6064658f8b0d97f72b6718e9",
            "name": "Kids Hamburger Menü",
            "priceText": "₺ 28,99",
            "price": 28.99,
            "description": "Kids Hamburger, Orta Boy Patates, Kutu İçecek ve Sürpriz Oyuncak",
            "imageURL": "https://cdn.getiryemek.com/products/1623328575508_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1623328575508_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "606466142bf0d212ba811854",
            "name": "Tavuklu Kids Menü",
            "priceText": "₺ 27,99",
            "price": 27.99,
            "description": "Tavuklu Kids, Orta Boy Patates, Kutu İçecek ve Sürpriz Oyuncak",
            "imageURL": "https://cdn.getiryemek.com/products/1623328614643_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1623328614643_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "606466ef4df3488fd0ab5eaa",
            "name": "Kids Chicken Tenders® Menü",
            "priceText": "₺ 27,99",
            "price": 27.99,
            "description": "4'lü Chicken Tenders®, Orta Boy Patates, Kutu İçecek ve Sürpriz Oyuncak",
            "imageURL": "https://cdn.getiryemek.com/products/1623328639213_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1623328639213_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "60646766b71fbc39861ab06f",
            "name": "Kids Cheese Menü",
            "priceText": "₺ 30,99",
            "price": 30.99,
            "description": "Kids Cheeseburger, Orta Boy Patates, Kutu İçecek ve Sürpriz Oyuncak",
            "imageURL": "https://cdn.getiryemek.com/products/1623328626847_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1623328626847_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "606467f134f5ff3b8258e823",
            "name": "Tavuklu Kids Cheese Menü",
            "priceText": "₺ 29,99",
            "price": 29.99,
            "description": "Tavuklu Kids Cheese, Orta Boy Patates, Kutu İçecek ve Sürpriz Oyuncak",
            "imageURL": "https://cdn.getiryemek.com/products/1623328656086_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1623328656086_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          }
        ]
      },
      {
        "id": "606250d53730cf671f531956",
        "name": "Burger",
        "products": [
          {
            "id": "6062e08b65ce6845f5abe8f9",
            "name": "Big King XXL®",
            "priceText": "₺ 41,99",
            "price": 41.99,
            "description": "Whopper® eti (dana), 5'' ekmek, 4 dilim peynir, turşu, Big King sosu, göbek salata, soğan",
            "imageURL": "https://cdn.getiryemek.com/products/1618059419376_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618059419376_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6062e2e065ce6864ccac0918",
            "name": "Big King®",
            "priceText": "₺ 25,50",
            "price": 25.5,
            "description": "Hamburger eti (dana), 4'' ekmek, 1 dilim peynir, turşu, Big King sosu, göbek salata, soğan",
            "imageURL": "https://cdn.getiryemek.com/products/1618059438964_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618059438964_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6062e33589ffd36611c654ca",
            "name": "Big King®Jr.",
            "priceText": "₺ 21,99",
            "price": 21.99,
            "description": "Hamburger eti (dana), 4'' ekmek, 1 dilim peynir, turşu, Big King sosu, göbek salata, soğan",
            "imageURL": "https://cdn.getiryemek.com/products/1618059509974_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618059509974_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6062e3eaf2765e2687991326",
            "name": "BK Kral Burger",
            "priceText": "₺ 26,99",
            "price": 26.99,
            "description": "4,5'' patatesli ekmek, BK Kral Burger eti (dana), garlic sos, domates, göbek salata, 1 dilim peynir",
            "imageURL": "https://cdn.getiryemek.com/products/1618059458145_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618059458145_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6062e4be8a29fa07e92e4dde",
            "name": "BK Steakhouse Burger®",
            "priceText": "₺ 31,99",
            "price": 31.99,
            "description": "Baharatlı biftek, 4,5'' ekmek, mayonez, domates, göbek salata, 2 dilim peynir, özel steak sos, kızartılmış kaplamalı soğan",
            "imageURL": "https://cdn.getiryemek.com/products/1618059467754_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618059467754_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6062e5bf69de6c2d404c1775",
            "name": "Cheeseburger",
            "priceText": "₺ 16,99",
            "price": 16.99,
            "description": "Hamburger eti (dana), 4'' ekmek, 1 dilim peynir, turşu, hardal, ketçap",
            "imageURL": "https://cdn.getiryemek.com/products/1618059491285_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618059491285_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6062e754d79a7968ad49644b",
            "name": "Chicken Royale®",
            "priceText": "₺ 24,99",
            "price": 24.99,
            "description": "Chicken Royale® eti, 7'' ekmek, mayonez, göbek salata",
            "imageURL": "https://cdn.getiryemek.com/products/1618059525475_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618059525475_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6062e7f48a29fa46772e6e3f",
            "name": "Double Big King®",
            "priceText": "₺ 39,99",
            "price": 39.99,
            "description": "Big King ekmeği, 4 adet hamburger eti, Big King sos, göbek salata, soğan, turşu, bir dilim cheddar peyniri",
            "imageURL": "https://cdn.getiryemek.com/products/1618059598428_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618059598428_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6062e9014df348db23aa10fb",
            "name": "Double Cheeseburger",
            "priceText": "₺ 24,50",
            "price": 24.5,
            "description": "Hamburger eti (dana), 4'' ekmek, 2 dilim peynir, turşu, hardal, ketçap",
            "imageURL": "https://cdn.getiryemek.com/products/1618059641465_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618059641465_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6062eadcbf03aa360131e2ef",
            "name": "Double King Chicken®",
            "priceText": "₺ 24,99",
            "price": 24.99,
            "description": "King Chicken tavuk eti, 4,25'' ekmek, mayonez, göbek salata",
            "imageURL": "https://cdn.getiryemek.com/products/1618060109168_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618060109168_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6062f5794df348fdddaa349c",
            "name": "Double Köfteburger®",
            "priceText": "₺ 24,99",
            "price": 24.99,
            "description": "1 dilim peynir, turşu, hardal, göbek salata, mayonez",
            "imageURL": "https://cdn.getiryemek.com/products/1618059740542_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618059740542_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6063a84b69de6c57274c4b8c",
            "name": "Double Texas Smokehouse Burger®",
            "priceText": "₺ 46,99",
            "price": 46.99,
            "description": "Texas Smokehouse Burger®, 2 adet Whopper® eti (dana), 4,5” ekmek, 4 dilim cheddar peyniri, füme et, barbekü sos, çıtır soğan",
            "imageURL": "https://cdn.getiryemek.com/products/1618059936083_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618059936083_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6063a9234df34807b5aa6319",
            "name": "Double Whopper®",
            "priceText": "₺ 34,99",
            "price": 34.99,
            "description": "Whopper® eti (dana), 5'' ekmek, turşu, ketçap, mayonez, göbek salata, domates, soğan",
            "imageURL": "https://cdn.getiryemek.com/products/1618059996079_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618059996079_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6063aa39d79a794b7c4990eb",
            "name": "Etli Barbekü Brioche®",
            "priceText": "₺ 29,99",
            "price": 29.99,
            "description": "2 dilim peynir, marul, mayonez, barbekü sos, çıtır soğan",
            "imageURL": "https://cdn.getiryemek.com/products/1618060019075_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618060019075_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6063ab2caa47297ed5aabe93",
            "name": "Etli Barbekü Deluxe Burger",
            "priceText": "₺ 22,99",
            "price": 22.99,
            "description": "Hamburger eti (dana), 3,75'' ekmek, 1 cheddar peynir, mayonez, göbek salata, barbekü sos, çıtır soğan",
            "imageURL": "https://cdn.getiryemek.com/products/1618060046729_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618060046729_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6063abc72b0371fdf8dc9523",
            "name": "Etli Master Burger",
            "priceText": "₺ 29,99",
            "price": 29.99,
            "description": "Whopper®️ eti (dana), 5'' ekmek, mantar sos,mozzarella peyniri, çıtır soğan, mayonez",
            "imageURL": "https://cdn.getiryemek.com/products/1618060140126_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618060140126_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6063ac58f4083ec81587e2dc",
            "name": "Fish Royale®",
            "priceText": "₺ 29,99",
            "price": 29.99,
            "description": "Fish Royale®, 4'' ekmek, tartar sos, göbek salata",
            "imageURL": "https://cdn.getiryemek.com/products/1618060091385_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618060091385_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6063ad443730cf028853bb3e",
            "name": "Hamburger",
            "priceText": "₺ 14,99",
            "price": 14.99,
            "description": "Hamburger eti (dana), 4'' ekmek, turşu, hardal, ketçap",
            "imageURL": "https://cdn.getiryemek.com/products/1618060147010_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618060147010_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6063ae1253493fbaadd445d2",
            "name": "Kids Cheese",
            "priceText": "₺ 16,99",
            "price": 16.99,
            "description": "Hamburger eti (dana), 4'' ekmek, 1 dilim peynir, ketçap",
            "imageURL": "https://cdn.getiryemek.com/products/1618060223871_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618060223871_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6063ae73f0248bd360dd00c9",
            "name": "Kids Hamburger",
            "priceText": "₺ 14,99",
            "price": 14.99,
            "description": "Hamburger eti (dana), 4'' ekmek, ketçap",
            "imageURL": "https://cdn.getiryemek.com/products/1618060260374_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618060260374_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6063aeac69de6ce7864c6a03",
            "name": "King Chicken®",
            "priceText": "₺ 20,99",
            "price": 20.99,
            "description": "King Chicken® tavuk eti, 4,25'' ekmek, mayonez, göbek salata",
            "imageURL": "https://cdn.getiryemek.com/products/1618060276121_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618060276121_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6063af1bddb30b41a54a1266",
            "name": "Klasik Gurme Tavuk®",
            "priceText": "₺ 28,99",
            "price": 28.99,
            "description": "Klasik Gurme Tavuk® eti, 4,5'' ekmek, mayonez, göbek salata, domates",
            "imageURL": "https://cdn.getiryemek.com/products/1618060316140_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618060316140_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6063af7fddb30b620a4a347f",
            "name": "Köfteburger®",
            "priceText": "₺ 17,50",
            "price": 17.5,
            "description": "Turşu, ketçap, mayonez, göbek salata",
            "imageURL": "https://cdn.getiryemek.com/products/1618060361962_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618060361962_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6063b02d82a0d0deb4144893",
            "name": "Mangalda Izgara Tavuk",
            "priceText": "₺ 27,99",
            "price": 27.99,
            "description": "4,5'' ekmek, kalça tavuk, mayonez, 2 dilim domates, göbek salata, 2 dilim cheddar peyniri, kızartılmış kaplamalı soğan, barbekü sos",
            "imageURL": "https://cdn.getiryemek.com/products/1618060409165_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618060409165_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6063b062e81f5b34bf86e047",
            "name": "Mega Double Cheeseburger",
            "priceText": "₺ 41,99",
            "price": 41.99,
            "description": "Hamburger eti (dana), 5'' ekmek, 4 dilim peynir, turşu, hardal, ketçap",
            "imageURL": "https://cdn.getiryemek.com/products/1618060725264_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618060725264_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6063b0f52f111e3d7a8dc82c",
            "name": "Planty Whopper®",
            "priceText": "₺ 29,99",
            "price": 29.99,
            "description": "Sebze proteininden oluşan Planty Whopper®, 5'' ekmek, turşu, ketçap, mayonez, göbek salata, domates, soğan",
            "imageURL": "https://cdn.getiryemek.com/products/1618060573154_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618060573154_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6063b14b2f111ef7708de687",
            "name": "Triple Whopper®",
            "priceText": "₺ 51,99",
            "price": 51.99,
            "description": "Whopper® eti (dana), 5'' ekmek, turşu, ketçap, mayonez, göbek salata, domates, soğan",
            "imageURL": "https://cdn.getiryemek.com/products/1618060753455_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618060753455_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6063b1874b0475cec25a7254",
            "name": "Whopper Jr.®",
            "priceText": "₺ 22,99",
            "price": 22.99,
            "description": "Hamburger eti (dana), 4'' ekmek, turşu, ketçap, mayonez, göbek salata, domates, soğan",
            "imageURL": "https://cdn.getiryemek.com/products/1618060763333_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618060763333_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6063b1e72bf0d294cd8087fa",
            "name": "Whopper®",
            "priceText": "₺ 29,99",
            "price": 29.99,
            "description": "Whopper® eti (dana), 5'' ekmek, turşu, ketçap, mayonez, göbek salata, domates, soğan",
            "imageURL": "https://cdn.getiryemek.com/products/1618060537836_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618060537836_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6063b2464b047506495a8432",
            "name": "Rodeo Whopper®",
            "priceText": "₺ 30,99",
            "price": 30.99,
            "description": "Whopper eti, 5\" ekmek, mayonez, göbek salata, 4 adet soğan halkası, 2 dilim domates, barbekü sos, 2 dilim peynir (Bu ürünün garnitürlerinde değişiklik yapılamamaktadır.)",
            "imageURL": "https://cdn.getiryemek.com/products/1618060823214_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618060823214_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6063b3038b08d17f1efba4b3",
            "name": "Spicy Gurme Tavuk",
            "priceText": "₺ 28,99",
            "price": 28.99,
            "description": "Spicy Gurme Tavuk® eti, 4,5'' ekmek, spicy sos, göbek salata, domates",
            "imageURL": "https://cdn.getiryemek.com/products/1618060653790_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618060653790_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6063b36369de6cf6464c884b",
            "name": "Tavukburger®",
            "priceText": "₺ 15,99",
            "price": 15.99,
            "description": "Tavukburger®, 4'' ekmek, mayonez, göbek salata",
            "imageURL": "https://cdn.getiryemek.com/products/1618060635086_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618060635086_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6063b3fc82a0d02976146a17",
            "name": "Tavukburger® Cheese",
            "priceText": "₺ 17,99",
            "price": 17.99,
            "description": "Tavukburger®, 4'' ekmek, peynir, mayonez, göbek salata",
            "imageURL": "https://cdn.getiryemek.com/products/1618060982627_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618060982627_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6063b4cf65ce686680ac3582",
            "name": "Tavuklu Barbekü Brioche®",
            "priceText": "₺ 25,96",
            "price": 25.96,
            "description": "2 dilim peynir, marul, mayonez, barbekü sos",
            "imageURL": "https://cdn.getiryemek.com/products/1618060798681_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618060798681_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6063b53098aa5bc2d8227012",
            "name": "Tavuklu Barbekü Deluxe Burger",
            "priceText": "₺ 20,99",
            "price": 20.99,
            "description": "Tavukburger eti, 3,75'' ekmek, 1 cheddar peynir, mayonez, göbek salata, barbekü sos, çıtır soğan",
            "imageURL": "https://cdn.getiryemek.com/products/1618060956119_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618060956119_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6063b5a46c2d47483950737e",
            "name": "Tavuklu Kids",
            "priceText": "₺ 14,99",
            "price": 14.99,
            "description": "Tavukburger®, 4'' ekmek, ketçap",
            "imageURL": "https://cdn.getiryemek.com/products/1618060848144_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618060848144_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6063b5ff2f111e45448df9f7",
            "name": "Tavuklu Master Burger",
            "priceText": "₺ 27,99",
            "price": 27.99,
            "description": "Klasik Gurme Tavuk®️ eti, 5'' ekmek, mantar sos, çıtır soğan, mayonez",
            "imageURL": "https://cdn.getiryemek.com/products/1618060863891_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618060863891_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6063b6947692c75490fcd42d",
            "name": "Texas Smokehouse Burger®",
            "priceText": "₺ 34,99",
            "price": 34.99,
            "description": "Texas Smokehouse Burger®, Whopper® eti (dana), 4,5” ekmek, 2 dilim cheddar peyniri, füme et, barbekü sos, çıtır soğan",
            "imageURL": "https://cdn.getiryemek.com/products/1618060941981_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618060941981_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6074c52f7509d55fb40a1e51",
            "name": "Pastırmalı Whopper®",
            "priceText": "₺ 32,99",
            "price": 32.99,
            "description": "Whopper® eti, pastırma, 5'' ekmek, salatalık turşusu, ketçap, mayonez, doğranmış marul, domates ve soğan",
            "imageURL": "https://cdn.getiryemek.com/products/1618266572522_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618266572522_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "60e443ba67b6676a62e358de",
            "name": "Bk Crispy Chicken",
            "priceText": "₺ 20,99",
            "price": 20.99,
            "description": "3,75\" ekmek, crispy chicken eti, mayonez, marul, domates",
            "imageURL": "https://cdn.getiryemek.com/products/1625572651303_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1625572651303_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          }
        ]
      },
      {
        "id": "6061c1e18b08d15d99fb6213",
        "name": "Delight",
        "products": [
          {
            "id": "6061c30998aa5b6ef521fb7b",
            "name": "Hot Wrap",
            "priceText": "₺ 17,99",
            "price": 17.99,
            "description": "Izgara but eti, tortilla ekmek, mayonez, göbek salata, domates",
            "imageURL": "https://cdn.getiryemek.com/products/1618061630610_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618061630610_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6061c41bd813fbc0c66dda47",
            "name": "King Delight® Akdeniz Salata",
            "priceText": "₺ 19,99",
            "price": 19.99,
            "description": "Taze marul, kıvırcık salata, dilimli siyah zeytin, küp kesilmiş beyaz peynir parçaları, cherry domates",
            "imageURL": "https://cdn.getiryemek.com/products/1618061467150_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618061467150_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6061c449ddb30be3434955c2",
            "name": "King Delight® Izgara Tavuklu Salata",
            "priceText": "₺ 19,99",
            "price": 19.99,
            "description": "Taze marul, kıvırcık salata, ızgara but eti, ince kaşar peyniri dilimleri, küp kesilmiş domates parçaları",
            "imageURL": "https://cdn.getiryemek.com/products/1618061572005_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618061572005_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6061c49d69de6c3bbb4bbe18",
            "name": "King Delight® Ton Balıklı Salata",
            "priceText": "₺ 19,99",
            "price": 19.99,
            "description": "Taze marul, kıvırcık salata, ton balığı parçaları, tatlı mısır konservesi",
            "imageURL": "https://cdn.getiryemek.com/products/1618061608782_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618061608782_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6061c4d87692c734a8fc4fb5",
            "name": "Mini Salata",
            "priceText": "₺ 14,99",
            "price": 14.99,
            "description": "Akdeniz yeşilliği, cherry domates, salatalık",
            "imageURL": "https://cdn.getiryemek.com/products/1618061590723_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618061590723_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          }
        ]
      },
      {
        "id": "6061c1292b0371e988dc58ff",
        "name": "Çıtır Lezzet",
        "products": [
          {
            "id": "6061c540d813fb3e086ddc26",
            "name": "BK King Nuggets® (6'lı)",
            "priceText": "₺ 14,99",
            "price": 14.99,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618061661212_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618061661212_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "60642b3f9c5540dbddb3ba1a",
            "name": "BK King Nuggets® (9'lu)",
            "priceText": "₺ 16,99",
            "price": 16.99,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618061676799_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618061676799_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "60642b614c11b0fe423bc51d",
            "name": "Chicken Tenders® (4'lü)",
            "priceText": "₺ 10,99",
            "price": 10.99,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618061694730_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618061694730_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "60642b868843fe7ce07cad84",
            "name": "Chicken Tenders® (6'lı)",
            "priceText": "₺ 12,99",
            "price": 12.99,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618061755950_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618061755950_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "60642ba71a40c454ea3a0988",
            "name": "Chicken Tenders® (9'lu)",
            "priceText": "₺ 15,99",
            "price": 15.99,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618061720125_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618061720125_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "60642bca4f6acb6822460e13",
            "name": "Chicken Wings (4'lü)",
            "priceText": "₺ 13,50",
            "price": 13.5,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618061737990_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618061737990_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "60642bea079a25c3ce81028a",
            "name": "Chicken Wings (6'lı)",
            "priceText": "₺ 17,50",
            "price": 17.5,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618061777266_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618061777266_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "60642c06d79a79a65f49b397",
            "name": "Chicken Wings (8'li)",
            "priceText": "₺ 19,99",
            "price": 19.99,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618226216991_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618226216991_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "60642c2634f5ff28645828ea",
            "name": "Çıtır Peynir (4'lü)",
            "priceText": "₺ 10,99",
            "price": 10.99,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618061801952_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618061801952_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "60642c454b047523545ab3fb",
            "name": "Çıtır Peynir (6'lı)",
            "priceText": "₺ 14,50",
            "price": 14.5,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618061820416_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618061820416_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "60642c6998aa5ba97c22961f",
            "name": "Çıtır Peynir (9'lu)",
            "priceText": "₺ 16,99",
            "price": 16.99,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618061865976_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618061865976_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "60642d104b047566cd5ad235",
            "name": "Patates Kızartması (Büyük)",
            "priceText": "₺ 8,50",
            "price": 8.5,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618061955566_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618061955566_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "60642ecb6c2d471a9a50b8f6",
            "name": "Patates Kızartması (Dev Kutu)",
            "priceText": "₺ 16,99",
            "price": 16.99,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618061976299_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618061976299_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "60642f0fb48e84d6ae446125",
            "name": "Patates Kızartması (Küçük)",
            "priceText": "₺ 6,99",
            "price": 6.99,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618061995686_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618061995686_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "60642f334df348b22faa9f19",
            "name": "Patates Kızartması (Orta)",
            "priceText": "₺ 7,50",
            "price": 7.5,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618062019480_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618062019480_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "60642fcbb71fbc9c4b19cadb",
            "name": "Soğan Halkası (12'li)",
            "priceText": "₺ 12,99",
            "price": 12.99,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618061844968_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618061844968_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "60642ff153493f399cd4acc6",
            "name": "Soğan Halkası (16'lı)",
            "priceText": "₺ 14,99",
            "price": 14.99,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618061930412_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618061930412_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "60643014d813fb1a5b6e4c9d",
            "name": "Soğan Halkası (8'li)",
            "priceText": "₺ 10,99",
            "price": 10.99,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618061910188_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618061910188_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6064303a079a25772e811aee",
            "name": "Tırtıklı Patates (Büyük)",
            "priceText": "₺ 8,50",
            "price": 8.5,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618062042845_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618062042845_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "606430621d92b2761b74d43f",
            "name": "Tırtıklı Patates (Dev Kutu)",
            "priceText": "₺ 16,99",
            "price": 16.99,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618062205531_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618062205531_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "606430861a40c4bddd3a59ca",
            "name": "Tırtıklı Patates (King)",
            "priceText": "₺ 9,50",
            "price": 9.5,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618062236045_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618062236045_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "606430c9f4083ef1af881df8",
            "name": "Tırtıklı Patates (Kova)",
            "priceText": "₺ 10,99",
            "price": 10.99,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618062087055_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618062087055_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "606430ee02bcfe5c09ea7f21",
            "name": "Tırtıklı Patates (Küçük)",
            "priceText": "₺ 6,50",
            "price": 6.5,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618062275035_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618062275035_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "606431151d92b28a2174eca4",
            "name": "Tırtıklı Patates (Orta)",
            "priceText": "₺ 7,50",
            "price": 7.5,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618062357089_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618062357089_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "606ec4c1d1b22e5195c82d67",
            "name": "Patates Kızartması (King)",
            "priceText": "₺ 9,50",
            "price": 9.5,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618062305922_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618062305922_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "606ec4fdb2c1eff5afff1209",
            "name": "Patates Kızartması (Kova)",
            "priceText": "₺ 10,99",
            "price": 10.99,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618062378578_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618062378578_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          }
        ]
      },
      {
        "id": "6061bb7089ffd36571c5faee",
        "name": "Tatlı",
        "products": [
          {
            "id": "6061bb7089ffd370d4c5fb54",
            "name": "Elmalı Tatlı",
            "priceText": "₺ 6,95",
            "price": 6.95,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618061030716_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618061030716_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6061bb7089ffd345fdc5fb53",
            "name": "Vişneli Tatlı",
            "priceText": "₺ 6,95",
            "price": 6.95,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618061045419_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618061045419_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6061bb7089ffd30364c5fb52",
            "name": "Algida Maraş Usulü Cup Dondurma (100 ml)",
            "priceText": "₺ 7,00",
            "price": 7,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618061062105_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618061062105_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6061bb7089ffd36fe8c5fb51",
            "name": "Algida Carte d'Or Mini Cup Çikolata Karnavalı (110 ml)",
            "priceText": "₺ 7,00",
            "price": 7,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618061079772_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618061079772_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6061bb7089ffd31667c5fb4c",
            "name": "Algida Carte d'Or Mini Cup Çilekli Yoğurtlu (110 ml)",
            "priceText": "₺ 7,00",
            "price": 7,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618061101082_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618061101082_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6061bb7089ffd3a5c4c5fb4f",
            "name": "Algida Mini Nogger (60 ml)",
            "priceText": "₺ 7,00",
            "price": 7,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618061236978_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618061236978_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6061bb7089ffd363b6c5fb4d",
            "name": "Algida Classics Cookie Cup (105 ml)",
            "priceText": "₺ 7,00",
            "price": 7,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618061308470_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618061308470_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6061bb7089ffd328c5c5fb50",
            "name": "Algida Magnum Mini Cookie (58 ml)",
            "priceText": "₺ 7,00",
            "price": 7,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618061350399_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618061350399_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6061bb7089ffd3502bc5fb4e",
            "name": "Çikolatalı Sufle",
            "priceText": "₺ 7,95",
            "price": 7.95,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618061170885_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618061170885_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6061bb7089ffd3677dc5fb4b",
            "name": "Dondurmalı Çikolatalı Sufle",
            "priceText": "₺ 8,95",
            "price": 8.95,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618061207518_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618061207518_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6061bb7089ffd31d05c5fb4a",
            "name": "Apple Pie",
            "priceText": "₺ 8,95",
            "price": 8.95,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618061258729_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618061258729_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6061bc44d813fb1f046dc650",
            "name": "Bkool® Ailesi",
            "priceText": "₺ 7,50",
            "price": 7.5,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618061288007_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618061288007_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6061c0048843fe989c7c202b",
            "name": "Milkshake",
            "priceText": "₺ 7,50",
            "price": 7.5,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618062411708_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618062411708_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6061c08cd813fb33d66dd1cf",
            "name": "King Sundae",
            "priceText": "₺ 6,50",
            "price": 6.5,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618061374598_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618061374598_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6061c11424e239eb8613551c",
            "name": "Sundae",
            "priceText": "₺ 5,95",
            "price": 5.95,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618061132078_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618061132078_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          }
        ]
      },
      {
        "id": "6061bb7089ffd31a9bc5faed",
        "name": "İçecek",
        "products": [
          {
            "id": "6061bb7089ffd35964c5fb41",
            "name": "Su (500 ml)",
            "priceText": "₺ 2,50",
            "price": 2.5,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618059836554_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618059836554_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6061bb7089ffd3ce18c5fb43",
            "name": "Coca Cola (330 ml)",
            "priceText": "₺ 7,50",
            "price": 7.5,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618061423846_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618061423846_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6061bb7089ffd35d69c5fb45",
            "name": "Coca Cola Zero Sugar (330 ml)",
            "priceText": "₺ 7,50",
            "price": 7.5,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618061444971_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618061444971_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6061bb7089ffd3ac5bc5fb42",
            "name": "Coca Cola Light (330 ml)",
            "priceText": "₺ 7,50",
            "price": 7.5,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618062472046_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618062472046_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6061bb7089ffd3e123c5fb48",
            "name": "Fuse Tea Şeftali (330 ml)",
            "priceText": "₺ 7,50",
            "price": 7.5,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618062457624_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618062457624_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6061bb7089ffd33ba9c5fb6d",
            "name": "Fuse Tea Limon (330 ml)",
            "priceText": "₺ 7,50",
            "price": 7.5,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618062491945_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618062491945_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6061bb7089ffd3335fc5fb6b",
            "name": "Sprite (330 ml)",
            "priceText": "₺ 7,50",
            "price": 7.5,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618062523125_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618062523125_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6061bb7089ffd37faac5fb69",
            "name": "Fanta (330 ml)",
            "priceText": "₺ 7,50",
            "price": 7.5,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618062533545_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618062533545_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6061bb7089ffd380aac5fb67",
            "name": "Ayran (195 ml)",
            "priceText": "₺ 4,95",
            "price": 4.95,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618062573100_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618062573100_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6061bb7089ffd36b2cc5fb66",
            "name": "Ayran (300 ml)",
            "priceText": "₺ 5,95",
            "price": 5.95,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618062593314_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618062593314_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6061bb7089ffd34e78c5fb5f",
            "name": "Cappy Atom (200 ml)",
            "priceText": "₺ 4,50",
            "price": 4.5,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618062657225_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618062657225_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6061bb7089ffd33f64c5fb5e",
            "name": "Cappy Elma Suyu (200 ml)",
            "priceText": "₺ 4,50",
            "price": 4.5,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618062678934_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618062678934_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6061bb7089ffd3356ac5fb5b",
            "name": "Sütaş Sade Süt (200 ml)",
            "priceText": "₺ 4,50",
            "price": 4.5,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618062715148_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618062715148_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6061bb7089ffd368ffc5fb5a",
            "name": "Sütaş Çikolatalı Süt (200 ml)",
            "priceText": "₺ 4,50",
            "price": 4.5,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618061405462_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618061405462_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6061bb7089ffd36772c5fb56",
            "name": "Coca Cola (1 L)",
            "priceText": "₺ 8,50",
            "price": 8.5,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618059935208_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618059935208_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6061bb7089ffd37d7cc5fb57",
            "name": "Coca Cola Zero Sugar (1 L)",
            "priceText": "₺ 8,50",
            "price": 8.5,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618059902453_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618059902453_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6061bb7089ffd34593c5fb59",
            "name": "Coca Cola Light (1 L)",
            "priceText": "₺ 8,50",
            "price": 8.5,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618059951083_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618059951083_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6061bb7089ffd39748c5fb58",
            "name": "Fuse Tea Limon (1 L)",
            "priceText": "₺ 8,50",
            "price": 8.5,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618059820544_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618059820544_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6061bb7089ffd3f11dc5fb5c",
            "name": "Fuse Tea Şeftali (1 L)",
            "priceText": "₺ 8,50",
            "price": 8.5,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618059793249_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618059793249_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6061bb7089ffd3833fc5fb5d",
            "name": "Fanta (1 L)",
            "priceText": "₺ 8,50",
            "price": 8.5,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618059764320_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618059764320_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6061bb7089ffd36021c5fb55",
            "name": "Sprite (1 L)",
            "priceText": "₺ 8,50",
            "price": 8.5,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618059742164_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618059742164_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          }
        ]
      },
      {
        "id": "6061bb7089ffd373b6c5faef",
        "name": "Sos",
        "products": [
          {
            "id": "6061bb7089ffd3ad99c5fb40",
            "name": "Acı Sos",
            "priceText": "₺ 1,25",
            "price": 1.25,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618059033120_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618059033120_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6061bb7089ffd329b8c5fb44",
            "name": "Barbekü Sos",
            "priceText": "₺ 1,50",
            "price": 1.5,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618059158689_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618059158689_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6061bb7089ffd32a76c5fb49",
            "name": "Ballı Hardal",
            "priceText": "₺ 1,50",
            "price": 1.5,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618059664236_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618059664236_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6061bb7089ffd34050c5fb6c",
            "name": "Ketçap",
            "priceText": "₺ 1,25",
            "price": 1.25,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618059515852_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618059515852_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6061bb7089ffd30b51c5fb6a",
            "name": "Mayonez",
            "priceText": "₺ 1,25",
            "price": 1.25,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618059691392_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618059691392_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6061bb7089ffd30b34c5fb68",
            "name": "Ranch Sos",
            "priceText": "₺ 1,50",
            "price": 1.5,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618059446348_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618059446348_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "6061bb7089ffd3033ac5fb65",
            "name": "Sarımsaklı Mayonez",
            "priceText": "₺ 1,25",
            "price": 1.25,
            "description": "",
            "imageURL": "https://cdn.getiryemek.com/products/1618059275631_500x375.jpeg",
            "fullScreenImageURL": "https://cdn.getiryemek.com/products/1618059275631_1000x750.jpeg",
            "restaurant": "605cb68c65ce68464daa6818"
          },
          {
            "id": "60dcd46034cb8d0b50d9482e",
            "name": "Zeytinyağı & Limon Sosu",
            "priceText": "₺ 2,00",
            "price": 2,
            "description": "",
            "imageURL": "",
            "fullScreenImageURL": "",
            "restaurant": "605cb68c65ce68464daa6818"
          }
        ]
      }
    ]
  },
  "result": {
    "code": 0,
    "message": "SUCCESS!!!"
  }
};