import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

import colors from "vuetify/lib/util/colors";

export default new Vuetify({
	theme: {
		themes: {
			light: {
				primary: "#0066FF",
				secondary: colors.grey.darken1,
				primaryTextColor: "#484F55",
				accent: colors.shades.black,
				error: colors.red.accent3,
			},
			dark: {
				primary: colors.blue.lighten3,
			},
		},
	},
});
