<template>
	<div :id="id" class="swiper-container">
		<div class="swiper-wrapper">
			<v-list-item class="swiper-slide">
				<v-list-item-icon>
					<v-img :src="product.imageURL" height="64" width="64" contain />
				</v-list-item-icon>
				<v-list-item-content align="start">
					<v-list-item-title>
						<h4>
							{{ product.name }}
						</h4>
					</v-list-item-title>
					<v-list-item-subtitle>
						<h5 class="primary--text">
							₺ {{ product.defaultProductUnit.price }} x {{ product.quantity }} = ₺
							{{ (product.defaultProductUnit.price * product.quantity).toFixed(2) }}
						</h5>
					</v-list-item-subtitle>
				</v-list-item-content>
			</v-list-item>
			<div class="swiper-slide red d-flex align-center" v-bind:style="{ height: height + 'px' }" align="start">
				<v-icon color="white" class="ma-4"> mdi-delete </v-icon>
			</div>
		</div>
	</div>
</template>

<script>
import "swiper/swiper.min.css";
import { Swiper } from "swiper";

export default {
	name: "CartItem",
	props: ["id", "product"],
	data: function() {
		return {
			swiper: null,
			height: 100,
		};
	},
	mounted() {
		const self = this;
		const el = "#" + self.id;

		self.swiper = new Swiper(el, {
			initialSlide: 0,
			resistanceRatio: 0,
			speed: 150,
			autoHeight: true,
		});

		self.height = self.swiper.slides[0].getBoundingClientRect().height;

		self.swiper.on("slideChangeTransitionEnd", function() {
			self.$store.commit("clearFromCart", self.product);
			this.destroy();
		});
	},
};
</script>
