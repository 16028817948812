<template>
	<v-app>
		<div v-if="!isLoading && !isError">
			<v-app-bar app flat color="#fff">
				<v-toolbar-title>
					<h4>{{ menu.name }}</h4>
				</v-toolbar-title>
				<v-spacer />

				<!-- @click="cartSheet = !cartSheet" -->

				<!-- <v-btn v-if="cart.length != 0" @click="$router.push(`/cart/${menuId}`)" icon>
					<v-badge :content="cartCount" :value="cartCount" color="primary" overlap>
						<v-icon> mdi-basket-outline </v-icon>
					</v-badge>
				</v-btn> -->

				<!-- <v-btn icon @click="infoSheet = !infoSheet">
					<v-icon> mdi-information-outline </v-icon>
				</v-btn> -->
				<template v-slot:extension>
					<v-chip-group mandatory active-class="primary--text" v-model="tab">
						<v-chip v-for="productCategory in menu.categories" :key="productCategory.id" @click="scrollTo(productCategory.id)" label>
							<h5>{{ productCategory.name }}</h5>
						</v-chip>
					</v-chip-group>
				</template>
			</v-app-bar>

			<v-main>
				<v-row justify="center" class="mx-0">
					<v-col v-for="productCategory in menu.categories" :key="productCategory.id" :id="productCategory.id" cols="12" sm="6" md="6" lg="4" xl="3">
						<v-list-item-content align="start" class="ma-4">
							<h3 class="primary--text">
								{{ productCategory.name }}
							</h3>
						</v-list-item-content>
						<Product v-for="product in productCategory.products" :key="product.id" :product="product" />
					</v-col>
				</v-row>
			</v-main>

			<v-bottom-sheet v-model="infoSheet">
				<v-sheet class="text-center" height="60vh" style="border-radius: 15px 15px 0 0">
					<div class="py-3">
						<h4>{{ menu.name }}</h4>
					</div>
					<!-- <v-card class="ma-4">
						<v-img :src="menu.imageURL" />
					</v-card> -->

					<v-row></v-row>
				</v-sheet>
			</v-bottom-sheet>

			<v-bottom-sheet v-model="cartSheet" scrollable>
				<v-card class="text-center" height="60vh" style="border-radius: 15px 15px 0 0">
					<div class="py-3">
						<h3>Basket</h3>
					</div>

					<v-card-text>
						<v-list v-if="cart.length != 0" two-line flat>
							<div v-for="(product, index) in cart" :key="product.id">
								<v-list-item>
									<v-list-item-icon>
										<v-img :src="product.imageURL" height="64" width="64" contain />
									</v-list-item-icon>
									<v-list-item-content align="start">
										<v-list-item-title>
											<h3>
												{{ product.name }}
											</h3>
										</v-list-item-title>
										<v-list-item-subtitle>
											<h4 class="primary--text">₺{{ product.price }} x {{ product.quantity }} = ₺{{ product.price * product.quantity }}</h4>
										</v-list-item-subtitle>
									</v-list-item-content>
								</v-list-item>
								<v-divider v-if="index != cart.length - 1" />
							</div>
						</v-list>

						<v-container v-else style="margin-top: 20vh">
							<v-row justify="center" align="center">
								<v-icon color="primary"> mdi-information-outline </v-icon>
								<h4 class="ml-2">Cart is empty!</h4>
							</v-row>
						</v-container>
					</v-card-text>

					<v-card-actions v-if="cart.length > 0">
						<v-btn color="primary" width="100%" class="mb-2" large @click="$router.push(`/cart/${menuId}`)">
							<h2 class="white--text">
								₺
								{{ cartTotal }}
							</h2>
							<h2 class="white--text">&ensp;Onayla</h2>
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-bottom-sheet>

			<v-bottom-sheet v-model="productDetailsSheet" persistent>
				<v-sheet class="text-center" height="60vh" style="border-radius: 15px 15px 0 0">
					<v-btn @click="closeProductDetailsSheet()"></v-btn>
					<div class="py-3">
						<h3>{{ selectedProduct.name }}</h3>
						<h5>{{ selectedProduct.priceText }}</h5>
						<h4>{{ selectedProduct.description }}</h4>
					</div>
				</v-sheet>
			</v-bottom-sheet>
		</div>

		<div v-else>
			<v-skeleton-loader v-for="index in 10" :key="index" type="image, text, sentences" class="ma-4" elevation="2"></v-skeleton-loader>

			<v-dialog v-model="isError" persistent transition="dialog-bottom-transition" width="100%">
				<v-card>
					<v-container class="py-8">
						<div class="d-flex justify-center mb-4">
							<v-icon size="40">mdi-emoticon-sad-outline</v-icon>
						</div>

						<p class="title text-center my-0 py-0 grey--text text--darken-3">
							Oops! Something totally went wrong
						</p>

						<div class="d-flex justify-center mt-6">
							<v-btn @click="onRefresh" color="primary">
								<v-icon class="mr-2">mdi-refresh</v-icon>
								REFRESH
							</v-btn>
						</div>
						<p class="body-2 text-center mt-6 py-0 grey--text text--darken-1">
							If problem persist kindly write us at bartuozel@gmail.com
						</p>

						<div class="d-flex justify-center mt-2">
							<v-btn @click="onMailClick" class="red--text">
								<v-icon class="mr-2">mdi-email-edit-outline</v-icon>
								MAIL US
							</v-btn>
						</div>
					</v-container>
				</v-card>
			</v-dialog>

			<v-dialog persistent v-model="isLoading" transition="dialog-bottom-transition" width="100">
				<v-card>
					<v-progress-circular indeterminate color="primary" class="ma-4" />
				</v-card>
			</v-dialog>
		</div>
	</v-app>
</template>

<script>
import { getMenuById } from "../data/menu";

import Product from "../components/Product.vue";

export default {
	name: "Menu",
	components: { Product },
	data: function() {
		return {
			menuId: "",
			tab: null,
			infoSheet: false,
			cartSheet: false,
			isLoading: true,
			isError: false,
			error: null,
			menu: {},
		};
	},
	methods: {
		async getMenu() {
			this.menuId = this.$route.params.menuId;

			this.menu = getMenuById(this.menuId);
			document.title = this.menu.name;
			this.isLoading = false;
		},
		closeProductDetailsSheet() {
			this.$store.commit("clearSelectedProduct");
		},
		scrollTo(ref) {
			window.scrollTo({
				top: document.getElementById(ref).offsetTop,
				behavior: "smooth",
			});
		},
		handleScroll() {
			let index = 0;
			this.categoryIds.forEach((categoryId) => {
				const element = document.getElementById(categoryId);

				if (element.offsetTop - 24 < window.scrollY) {
					index = this.categoryIds.indexOf(categoryId);
				}
			});
			this.tab = index;
		},
		onRefresh() {
			location.reload();
		},
		onMailClick() {
			window.open(`mailto:bartuozel@gmail.com?subject=Bug Report&body=Hello,\n`);
		},
	},
	created: function() {
		window.scrollTo(0, 0);

		window.addEventListener("scroll", this.handleScroll);

		this.getMenu();
	},
	computed: {
		productDetailsSheet() {
			return this.$store.state.productDetailsSheet;
		},
		selectedProduct() {
			return this.$store.state.selectedProduct;
		},
		categoryIds() {
			return this.menu.categories.map((category) => category.id);
		},
		cart() {
			return this.$store.state.cart;
		},
		cartCount() {
			return this.$store.getters.cartLength;
		},
		cartTotal() {
			return this.$store.getters.cartTotal;
		},
	},
};
</script>
