<template>
	<div class="shadow px-2 py-2 mb-2">
		<v-row class="ma-0">
			<!-- @click="showDetails()" -->
			<v-col cols="3" class="pa-0" align-center align-content-center centered>
				<v-img v-if="product.imageURL != '' && product.imageURL != null" :src="product.imageURL" class="shadow fill-height" contain />

				<v-icon v-else> mdi-information-outline </v-icon>
			</v-col>

			<v-col cols="9" class="pl-5" align-content="space-between" align="start" flex>
				<v-row>
					<h3>{{ product.name }}</h3>
				</v-row>

				<v-row>
					<h6 class="subtitle">
						{{ product.description }}
					</h6>
				</v-row>

				<v-row class="pt-2">
					<v-sheet color="grey lighten-4" elevation="0" class="px-2" rounded>
						<v-col>
							<v-row justify="start" align="center">
								<h4 class="py-1 primaryTextColor--text">₺{{ product.defaultProductUnit.price.toFixed(2) }}</h4>

								<!-- <v-chip color="primary" class="pa-2 ml-3" label small @click="increase()">
									<v-icon> mdi-plus </v-icon>
								</v-chip>

								<h4 v-if="quantity != 0" class="mx-2 primaryTextColor--text">
									{{ quantity }}
								</h4>

								<v-chip v-if="quantity != 0" color="primary" class="pa-2" label small @click="decrease()">
									<v-icon>mdi-minus</v-icon>
								</v-chip> -->
							</v-row>
						</v-col>
					</v-sheet>
				</v-row>
			</v-col>
		</v-row>
	</div>
</template>

<script>
export default {
	props: ["product"],
	methods: {
		showDetails() {
			this.$store.commit("setSelectedProduct", this.product);
		},
		increase() {
			this.$store.commit("addToCart", this.product);
		},
		decrease() {
			if (this.quantity > 0) {
				this.$store.commit("removeFromCart", this.product);
			}
		},
	},
	computed: {
		quantity() {
			const tempProduct = this.$store.state.cart.find((product) => product.id === this.product.id);

			if (tempProduct) {
				return tempProduct.quantity;
			} else {
				return 0;
			}
		},
	},
};
</script>

<style>
.subtitle {
	font-weight: 500;
	text-align: justify;
	overflow: hidden;
	line-height: 1.3em;
}
</style>
