import { storeApi } from "../api";
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		storeSlug: "",
		store: {},
		error: null,
		isLoading: true,
		productDetailsSheet: false,
		selectedProduct: {},
		cart: [],
	},
	mutations: {
		setStoreSlug(state, slug) {
			state.storeSlug = slug;
		},
		getStore(state) {
			if (!state.storeSlug) {
				state.store = {};
				state.error = "no-store-slug";
			} else if (state.store.restaurant.slug != state.storeSlug) {
				state.isLoading = true;
				storeApi
					.getStore(state.storeSlug)
					.then((res) => {
						state.isLoading = false;
						if (res.status === 200) {
							state.store = res.data.data;
							state.error = null;
						} else {
							state.store = {};
							state.error = "unkown-error";
						}
					})
					.catch((err) => {
						state.isLoading = false;
						state.store = {};
						state.error = err || "unkown-error";
					});
			}
		},
		setSelectedProduct(state, product) {
			state.productDetailsSheet = true;
			state.selectedProduct = product;
		},
		clearSelectedProduct(state) {
			state.productDetailsSheet = false;
			state.selectedProduct = null;
		},
		addToCart(state, item) {
			const found = state.cart.find((product) => product.id == item.id);

			if (found) {
				found.quantity++;
			} else {
				state.cart.push({ quantity: 1, ...item });
			}
		},
		removeFromCart(state, item) {
			const found = state.cart.find((product) => product.id == item.id);

			if (found) {
				if (found.quantity == 1) {
					state.cart.splice(state.cart.indexOf(found), 1);
				} else {
					found.quantity--;
				}
			}
		},
		clearFromCart(state, item) {
			const found = state.cart.find((product) => product.id == item.id);

			if (found) {
				state.cart.splice(state.cart.indexOf(found), 1);
			}
		},
	},
	actions: {
		getStore({ commit }) {
			commit("getStore");
		},
	},
	getters: {
		cartLength: (state) => {
			return state.cart.map((product) => product.quantity).reduce((a, b) => a + b, 0);
		},
		cartTotal: (state) => {
			return state.cart
				.map((product) => product.defaultProductUnit.price * product.quantity)
				.reduce((a, b) => a + b, 0)
				.toFixed(2);
		},
	},
});
