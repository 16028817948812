import Vue from "vue";
import VueRouter from "vue-router";
import Cart from "../views/Cart.vue";
import Menu from "../views/Menu.vue";
import Waiter from "../views/Waiter.vue";

Vue.use(VueRouter);

const routes = [
	{
		path: "/waiter",
		name: "Waiter",
		component: Waiter,
	},
	{
		path: "/cart/:menuId",
		name: "Cart",
		component: Cart,
	},
	{
		path: "/:menuId",
		name: "Menu",
		component: Menu,
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

export default router;
